const { createSlice } = require("@reduxjs/toolkit");

const populatedHomepageSlice = createSlice({
    name: 'populatedHome',
    initialState: {
        isLoading: true
    },
    reducers: {
        setLoadedData(state, action) {
            state.isLoading = true
        },
        setSuccessLoadedData(state, action) {
            state.isLoading = false
        }
    }
})


export const populatedHomeReducer = populatedHomepageSlice.reducer
export const populatedHomeAction = populatedHomepageSlice.actions