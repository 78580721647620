import { configureStore } from "@reduxjs/toolkit";
import { anakPerusahaanReducer } from "./anakPerusahaan/slice";
import { beritaReducer } from "./berita/slice";
import { bisnisKamiReducer } from "./bisnisKami/slice";
import { dewanKomisarisReducer } from "./dewanKomisaris/slice";
import { galeriReducer } from "./galeri/slice";
import { populatedHomeReducer } from "./populatedHomepage/slice";
import { produkKamiReducer } from "./produkKami/slice";
import { sertifikasiReducer } from "./sertifikasi/slice";
import { sliderReducer } from "./slider/slice";

export const store = configureStore({
    reducer: {
        populatedHomeReducer: populatedHomeReducer,
        sliderReducer: sliderReducer,
        produkKamiReducer: produkKamiReducer,
        bisnisKamiReducer: bisnisKamiReducer,
        anakPerusahaanReducer: anakPerusahaanReducer,
        sertifikasiReducer: sertifikasiReducer,
        dewanKomisarisReducer: dewanKomisarisReducer,
        galeriReducer: galeriReducer,
        beritaReducer: beritaReducer
    }
})