import React, { useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  GridItem,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

export default function ManajemenResiko() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box mt="65px">
      <Box w="full" h="80" position="relative">
        <Image src="https://saranapatra.com/images/bgBanner.jpg" w="full" h="full" />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(10, 60, 126, 0.9)"
        >
          <Stack w="full" h="full" justifyContent="center" alignItems="center">
            <Stack justifyContent="center" alignItems="center" gap="4">
              <Text fontSize="4xl" fontWeight="bold" color="white" textAlign="center">
                Manajemen Resiko
              </Text>
              {/* <Stack direction={{ base: "column", md: "row" }} gap={{ base: 0, md: "4" }}>
              </Stack> */}
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box bg="gray.50" py="30">
        <Container maxW="6xl">
          <Grid templateColumns={{ base: "1fr", md: "auto 1fr" }} gap="10">
            <GridItem>
              <Text fontWeight="bold" fontSize="3xl" color="#0A3C7E">
                Sistem Manajemen Risiko
              </Text>
              <Box>
                <Image
                  src="https://saranapatra.com/images/manajemen-resiko.png"
                  mx={{ base: "auto" }}
                />
              </Box>
            </GridItem>
            <GridItem>
              <Stack spacing="4">
                <Text>
                  Dalam rangka meningkatkan nilai tambah, PT Sarana Patra Jateng secara
                  konsisten mengelola Sistem Manajemen Risiko dengan berpedoman
                  pada regulasi dan Perundangan yang berlaku di Indonesia.
                </Text>
                <Text>
                  Perseroan senantiasa mengedepankan praktek bisnis yang sehat
                  dan menerapkan prinsip kehati-hatian (prudent) dalam mengelola
                  segala jenis risiko sebagai komitmen SPJ dalam menjalankan
                  tata kelola perusahaan yang baik.
                </Text>
                <Text color="#0A3C7E" fontSize="lg">
                  Kebijakan dan Ruang Lingkup Whistleblowing System
                </Text>
                <Text>
                  SPJ senantiasa memperhatikan kepentingan Stakeholder berdasar
                  asas kewajaran dan kesetaraan. Perseroan menyadari perlu
                  adanya mekanisme penanganan pelanggaran sebagai salah satu
                  bentuk perlindungan terhadap reputasi Perseroan.
                </Text>
                <Text>
                  Ruang lingkup pengaduan pelanggaran meliputi Pihak Internal
                  mulai dari Dewan Komisaris, Direksi dan Karyawan serta
                  Pemangku Kepentingan.
                </Text>
              </Stack>
            </GridItem>
          </Grid>

          <Stack mt="10" spacing="5">
            <Text fontWeight="bold" fontSize="xl" color="#0A3C7E">
              Mekanisme Penyampaian Laporan dan Pengelolaan Laporan
              Wistleblowing System
            </Text>
            <Text>
              Perseroan telah menyediakan media sebagai fasilitas Para Pemangku
              Kepentingan dalam menyampaikan laporannya dalam hal adanya dugaan
              terjadinya pelanggaran yang dilakukan oleh Insan SPJ, melalui
              mekanisme:
            </Text>
            <Stack>
              <Text>Email : spj@saranapatra.com, atau</Text>
              <Text>Disampaikan melalui surat resmi ke</Text>
              <Text>PT Sarana Patra Jateng</Text>
              <Text>Jl. Pamularsih Raya No. 58 Semarang, 50148</Text>
              <Text>Fax : 024 - 76630507</Text>
            </Stack>
            <Text>
              Perseroan memiliki komitmen yang jelas dan tidak memihak untuk
              mendukung dan melindungi semua Pelapor yang menginformasikan
              kejadian pelanggaran yang terjadi di PT Sarana Patra Jateng.
            </Text>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
