import { Box, Container, Flex, Grid, GridItem, Stack, Text, Image, Link, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper"
import FadeInSection from '../atoms/MyFade';
import { useSelector } from 'react-redux';

export default function ProdukKami() {
  const [isMaxW913] = useMediaQuery('(max-width: 914px)')
  const {produkKamiReducer} = useSelector(state => state)

  return (
    <Box w="full" minH="214px" bg="white">
      <Container maxW="6xl" p={6}>
        <Grid templateColumns={isMaxW913 ? "1fr" : "200px 1fr"} gap={46}>
          <GridItem>
            <Flex align="center" flexDirection="column" h="100%" justifyContent="center">
              <FadeInSection>
                <Text fontSize={30} fontWeight="bold" textAlign="center">Produk Kami</Text>
              </FadeInSection>
            </Flex>
          </GridItem>
          <GridItem overflow="hidden">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              loop={true}
              freeMode={true}
              modules={[Navigation, Pagination]}
              className="mySwiper2"
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 0
                },
                576: {
                  slidesPerView: 2,
                  spaceBetween: 0
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 0
                },
                1276: {
                  slidesPerView: 3,
                  spaceBetween: 0
                }
              }}
            >
              {
                produkKamiReducer.data.map((produkKami, id) => (
                  <SwiperSlide key={id}>
                    <Stack justifyContent="center" alignItems="center" spacing={4}>
                      <Image height="32" src={produkKami.lokasi_gambar} alt={produkKami.judul} title={produkKami.judul} />
                      <Link _hover={{ textDecotation: "none" }}>
                        <Text
                          onClick={() => window.open(produkKami.link, "_blank")} 
                          fontWeight="bold" color="#0A3C7E" sx={{ fontStyle:"italic" }}>Info Detail</Text>
                      </Link>
                    </Stack>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  )
}
