import React, { useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  GridItem,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

export default function StrukturTataKelola() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box mt="65px">
      <Box w="full" h="80" position="relative">
        <Image src="https://saranapatra.com/images/bgBanner.jpg" w="full" h="full" />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(10, 60, 126, 0.9)"
        >
          <Stack w="full" h="full" justifyContent="center" alignItems="center">
            <Stack justifyContent="center" alignItems="center" gap="4">
              <Text fontSize="4xl" fontWeight="bold" color="white" textAlign="center">
                Struktur Tata Kelola Perusahaan
              </Text>
              {/* <Stack direction={{ base: "column", md: "row" }} gap={{ base: 0, md: "4" }}>
              </Stack> */}
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box bg="gray.50" py="30">
        <Container maxW="6xl">
          <Grid templateColumns={{ base: "1fr", md: "auto 1fr" }} gap="10">
            <GridItem>
              <Box>
                <Image src="https://saranapatra.com/images/prinsip-GCG.png" mx={{ base: "auto" }} />
              </Box>
            </GridItem>
            <GridItem>
              <Stack>
                <Text fontWeight="bold" fontSize="3xl" color="#0A3C7E">
                  Gambaran Umum
                </Text>
                <Text>
                  Sebagai implementasi Peraturan Pemerintah Nomor 54 Tahun 2017
                  tentang Badan Usaha Milik Daerah, PT Sarana Patra Jateng sebagai anak
                  perusahaan PT Sarana Pembangunan Jawa Tengah berupaya menerapkan praktik tata kelola
                  perusahaan yang baik, yang menjadi salah satu sumber penggerak
                  Perusahaan untuk mencapai kinerja unggul dan membangun
                  kepercayaan dari Pemegang Saham dan Pemangku Kepentingan.
                </Text>
                <Text>
                  Pedoman Tata Kelola Perusahaan (Code of Corporate Governance)
                  merupakan dasar dan acuan dalam pengelolaan Perusahaan.
                  Pedoman ini memuat prinsip-prinsip yang berlaku bagi seluruh
                  aktivitas Perusahaan. Untuk itu Perusahaan senantiasa
                  melakukan peninjauan secara berkala atas perangkat kebijakan
                  dan pedoman Good Corporate Governance yang disesuaikan dengan
                  produk-produk hukum terkini agar mencapai standar kerja
                  terbaik bagi Perusahaan.
                </Text>
              </Stack>
            </GridItem>
          </Grid>

          <Stack mt="10" spacing="5">
            <Text fontWeight="bold" fontSize="3xl" color="#0A3C7E">
              Struktur Tata Kelola
            </Text>
            <Text>
              Sesuai dengan Undang-Undang No 40 Tahun 2007 tentang Perseroan
              terbatas, Organ perusahaan terdiri dari Rapat Umum Pemegang Saham
              (RUPS), Dewan Komisaris dan Direksi. Kepengurusan perseroan
              menganut sistem dua badan (two tier system), yaitu Dewan Komisaris
              dan Direksi, yang memiliki wewenang dan tanggung jawab yang jelas
              sesuai fungsinya masing-masing sebagaimana diamanatkan dalam
              Anggaran Dasar dan Peraturan Perundang-Undangan.
            </Text>
            <Text>
              Hubungan kerja yang harmonis antar Organ Perusahaan menjadi suatu
              energi yang besar bagi Perusahaan untuk mencapai sasaran
              Perusahaan. Masing-masing Organ Perusahaan berhubungan dengan
              memperhatikan prinsip kesetaraan dan saling menghargai tugas,
              tanggungjawab dan wewenang masing-masing. Struktur tata kelola
              merupakan organ Perusahaan yang memiliki peran stategis dalam
              penerapan Good Corporate Governance yang terdiri dari Organ Utama
              yaitu Rapat Umum Pemegang Saham (RUPS), Dewan Komisaris dan
              Direksi dan Organ Pendukung terdiri dari Sekretaris Perusahaan,
              Satuan Pengawasan Internal, Sekretaris Dewan Komisaris dan
              Komite-Komite.
            </Text>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
