const { createSlice } = require("@reduxjs/toolkit");

const beritaSlice = createSlice({
    name: 'berita',
    initialState: {
        data: [],
        isLoading: false,
        messageError: null
    },
    reducers: {
        setInitialData(state, action) {
            state.data = []
            state.isLoading = true
            state.messageError = null
        },
        setSuccessData(state, action) {
            state.data = action.payload
            state.isLoading = false
            state.messageError = null
        },
        setErrorData(state, action) {
            state.data = []
            state.isLoading = false
            state.messageError = action.payload
        }
    }
})

export const beritaReducer = beritaSlice.reducer
export const beritaAction = beritaSlice.actions