import { ChevronDownIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Link as NavigationLink, NavLink } from "react-router-dom";
import {
  Box,
  Collapse,
  Flex,
  IconButton,
  Image,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure();

  const { isOpen: openTentangSpjt, onToggle: onToggleTentangSpjt } =
    useDisclosure();
  const { isOpen: openTataKelola, onToggle: onToggleTataKelola } =
    useDisclosure();
  const { isOpen: openHubInvestor, onToggle: onToggleHubInvestor } =
    useDisclosure();
  const { isOpen: openPpid, onToggle: onTogglePpid } = useDisclosure();
  const { isOpen: openPublikasi, onToggle: onTogglePublikasi } =
    useDisclosure();
  const { isOpen: openDrm, onToggle: onToggleDrm } = useDisclosure();

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      width="full"
      zIndex="9999"
      bg="gray.50"
    >
      <Flex
        px={{ base: 8, md: 14 }}
        py={2}
        minH="60px"
        borderBottom={1}
        borderStyle="solid"
        borderColor="gray.100"
        align="center"
      >
        <Flex position="relative">
          <NavigationLink to="/">
            <Image w={128} h={50} src="https://saranapatra.com/images/logo_spj_new.png" alt="logo spjt" />
          </NavigationLink>
        </Flex>
        <Stack
          direction="row"
          flex="1"
          display={{ base: "none", md: "flex" }}
          ml={{ md: "50px" }}
          spacing={5}
          flexWrap="wrap"
        >
          <Box>
            <Popover trigger="hover" placement="bottom-start">
              <PopoverTrigger>
                <Link
                  _hover={{ textDecoration: "none", color: "#0A3C7E" }}
                  href="#"
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Text fontSize="sm" fontWeight="bold">
                      TENTANG SPJ
                    </Text>
                    <ChevronDownIcon w={4} h={4} />
                  </Stack>
                </Link>
              </PopoverTrigger>
              <PopoverContent p={2} width="-moz-fit-content">
                <Stack spacing={5}>
                  <NavigationLink to="/profil-perusahaan">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Profil Perusahaan
                    </Text>
                  </NavigationLink>
                  <NavigationLink to="/sertifikat">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Sertifikat
                    </Text>
                  </NavigationLink>
                  <NavigationLink to="/dewan-komisaris-dan-direksi">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Dewan Komisaris & Direksi
                    </Text>
                  </NavigationLink>
                  <NavigationLink to="/struktur-organisasi">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Struktur Organisasi
                    </Text>
                  </NavigationLink>
                </Stack>
              </PopoverContent>
            </Popover>
          </Box>
          <Box>
            <Popover trigger="hover" placement="bottom-start">
              <PopoverTrigger>
                <Link
                  _hover={{ textDecoration: "none", color: "#0A3C7E" }}
                  href="#"
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Text fontSize="sm" fontWeight="bold">
                      TATA KELOLA
                    </Text>
                    <ChevronDownIcon w={4} h={4} />
                  </Stack>
                </Link>
              </PopoverTrigger>
              <PopoverContent p={2} width="-moz-fit-content">
                <Stack spacing={5}>
                  <NavigationLink to="/struktur-tata-kelola">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Struktur Tata Kelola
                    </Text>
                  </NavigationLink>
                  <NavigationLink to="/manajemen-resiko">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Manajemen Resiko
                    </Text>
                  </NavigationLink>
                  <NavigationLink to="/whistle-blowing-system">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Whistle Blowing System
                    </Text>
                  </NavigationLink>
                </Stack>
              </PopoverContent>
            </Popover>
          </Box>
          <Box>
            <Popover trigger="hover" placement="bottom-start">
              <PopoverTrigger>
                <Link
                  _hover={{ textDecoration: "none", color: "#0A3C7E" }}
                  href="#"
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Text fontSize="sm" fontWeight="bold">
                      HUBUNGAN INVESTOR
                    </Text>
                    <ChevronDownIcon w={4} h={4} />
                  </Stack>
                </Link>
              </PopoverTrigger>
              <PopoverContent p={2} width="-moz-fit-content">
                <Stack spacing={5}>
                  <NavigationLink to="/informasi-pemegang-saham">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Informasi Pemegang Saham
                    </Text>
                  </NavigationLink>
                  {/* <NavigationLink to="/laporan-keuangan">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Laporan Keuangan
                    </Text>
                  </NavigationLink> */}
                  {/* <NavigationLink to="/laporan-tahunan">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Laporan Tahunan
                    </Text>
                  </NavigationLink>
                  <NavigationLink to="/rups">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      RUPS
                    </Text>
                  </NavigationLink> */}
                  <NavigationLink to="/keterbukaan-informasi-lainnya">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Keterbukaan Informasi Lainnya
                    </Text>
                  </NavigationLink>
                </Stack>
              </PopoverContent>
            </Popover>
          </Box>
          <Box>
            <Popover trigger="hover" placement="bottom-start">
              <PopoverTrigger>
                <Link
                  _hover={{ textDecoration: "none", color: "#0A3C7E" }}
                  href="#"
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Text fontSize="sm" fontWeight="bold">
                      PPID
                    </Text>
                    <ChevronDownIcon w={4} h={4} />
                  </Stack>
                </Link>
              </PopoverTrigger>
              <PopoverContent p={2} width="-moz-fit-content">
                <Stack spacing={5}>
                  <NavigationLink to="/alur-permohonan">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Alur Permohonan
                    </Text>
                  </NavigationLink>
                  {/* <NavigationLink to="/informasi-berkala">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Informasi Berkala
                    </Text>
                  </NavigationLink>
                  <NavigationLink to="/informasi-setiap-saat">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Informasi Setiap Saat
                    </Text>
                  </NavigationLink>
                  <NavigationLink to="/informasi-serta-merta">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Informasi Serta Merta
                    </Text>
                  </NavigationLink>
                  <NavigationLink to="/informasi-dikecualikan">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Informasi Dikecualikan
                    </Text>
                  </NavigationLink> */}
                </Stack>
              </PopoverContent>
            </Popover>
          </Box>
          <Box>
            <Popover trigger="hover" placement="bottom-start">
              <PopoverTrigger>
                <Link
                  _hover={{ textDecoration: "none", color: "#0A3C7E" }}
                  href="#"
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Text fontSize="sm" fontWeight="bold">
                      PUBLIKASI
                    </Text>
                    <ChevronDownIcon w={4} h={4} />
                  </Stack>
                </Link>
              </PopoverTrigger>
              <PopoverContent p={2} width="-moz-fit-content">
                <Stack spacing={5}>
                  <NavigationLink to="https://spjt.co.id/berita-kegiatan/" target="_blank">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Berita & Kegiatan
                    </Text>
                  </NavigationLink>
                  <NavigationLink to="https://spjt.co.id/pengadaan/" target="_blank">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Pengadaan Barang & Jasa
                    </Text>
                  </NavigationLink>
                </Stack>
              </PopoverContent>
            </Popover>
          </Box>
          <Box>
            <NavigationLink to="https://spjt.co.id/karir/" target="_blank">
              <Text
                fontWeight="bold"
                fontSize="sm"
                _hover={{
                  textDecoration: "none",
                  color: "#0A3C7E",
                }}
              >
                KARIR
              </Text>
            </NavigationLink>
          </Box>
          <Box>
            <Popover trigger="hover" placement="bottom-start">
              <PopoverTrigger>
                <Link
                  _hover={{ textDecoration: "none", color: "#0A3C7E" }}
                  href="#"
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Text fontSize="sm" fontWeight="bold">
                      DRM
                    </Text>
                    <ChevronDownIcon w={4} h={4} />
                  </Stack>
                </Link>
              </PopoverTrigger>
              <PopoverContent p={2} width="-moz-fit-content">
                <Stack spacing={5}>
                  <NavigationLink to="https://forms.gle/hdVL9WW4NLHMACx87" target="_blank">
                    <Text
                      _hover={{
                        textDecoration: "none",
                        color: "#0A3C7E",
                      }}
                    >
                      Daftar Rekanan Mampu (DRM)
                    </Text>
                  </NavigationLink>
                </Stack>
              </PopoverContent>
            </Popover>
          </Box>
          <Box>
            <NavigationLink to="/Kontak">
              <Text
                fontWeight="bold"
                fontSize="sm"
                _hover={{
                  textDecoration: "none",
                  color: "#0A3C7E",
                }}
              >
                KONTAK
              </Text>
            </NavigationLink>
          </Box>
        </Stack>
        <Flex
          flex={1}
          justify={"flex-end"}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? (
                <CloseIcon w={25} h={25} />
              ) : (
                <HamburgerIcon w={30} h={30} />
              )
            }
            variant="ghost"
          />
        </Flex>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Stack
          p={4}
          display={{ md: "none" }}
          borderBottom={0}
          borderStyle="solid"
          borderColor="gray.400"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontSize="14px" fontWeight="bold">
              TENTANG SPJT
            </Text>
            <ChevronDownIcon w={7} h={7} onClick={onToggleTentangSpjt} />
          </Stack>
          <Collapse in={openTentangSpjt} animateOpacity>
            <Flex direction="column" gap="2" ml={4}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/profil-perusahaan">
                  <Text fontSize="14px" fontWeight="bold">
                    Profil Perusahaan
                  </Text>
                </NavLink>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/sertifikat">
                  <Text fontSize="14px" fontWeight="bold">
                    Sertifikat
                  </Text>
                </NavLink>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/dewan-komisaris-dan-direksi">
                  <Text fontSize="14px" fontWeight="bold">
                    Dewan Komisaris & Direksi
                  </Text>
                </NavLink>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/struktur-organisasi">
                  <Text fontSize="14px" fontWeight="bold">
                    Struktur Organisasi
                  </Text>
                </NavLink>
              </Stack>
            </Flex>
          </Collapse>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontSize="14px" fontWeight="bold">
              TATA KELOLA
            </Text>
            <ChevronDownIcon w={7} h={7} onClick={onToggleTataKelola} />
          </Stack>
          <Collapse in={openTataKelola} animateOpacity>
            <Flex direction="column" gap="2" ml={4}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/struktur-tata-kelola">
                  <Text fontSize="14px" fontWeight="bold">
                    Struktur Tata Kelola
                  </Text>
                </NavLink>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/manajemen-resiko">
                  <Text fontSize="14px" fontWeight="bold">
                    Manajemen Resiko
                  </Text>
                </NavLink>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/whistle-blowing-system">
                  <Text fontSize="14px" fontWeight="bold">
                    Whistle Blowing System
                  </Text>
                </NavLink>
              </Stack>
            </Flex>
          </Collapse>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontSize="14px" fontWeight="bold">
              HUBUNGAN INVESTOR
            </Text>
            <ChevronDownIcon w={7} h={7} onClick={onToggleHubInvestor} />
          </Stack>
          <Collapse in={openHubInvestor} animateOpacity>
            <Flex direction="column" gap="2" ml={4}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/informasi-pemegang-saham">
                  <Text fontSize="14px" fontWeight="bold">
                    Informasi Pemegang Saham
                  </Text>
                </NavLink>
              </Stack>
              {/* <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/laporan-keuangan">
                  <Text fontSize="14px" fontWeight="bold">
                    Laporan Keuangan
                  </Text>
                </NavLink>
              </Stack> */}
              {/* <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/laporan-tahunan">
                  <Text fontSize="14px" fontWeight="bold">
                    Laporan Tahunan
                  </Text>
                </NavLink>
              </Stack> */}
              {/* <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/rups">
                  <Text fontSize="14px" fontWeight="bold">
                    RUPS
                  </Text>
                </NavLink>
              </Stack> */}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/keterbukaan-informasi-lainnya">
                  <Text fontSize="14px" fontWeight="bold">
                    Keterbukaan Informasi Lainnya
                  </Text>
                </NavLink>
              </Stack>
            </Flex>
          </Collapse>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontSize="14px" fontWeight="bold">
              PPID
            </Text>
            <ChevronDownIcon w={7} h={7} onClick={onTogglePpid} />
          </Stack>
          <Collapse in={openPpid} animateOpacity>
            <Flex direction="column" gap="2" ml={4}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/alur-permohonan">
                  <Text fontSize="14px" fontWeight="bold">
                    Alur Permohonan
                  </Text>
                </NavLink>
              </Stack>
              {/* <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/informasi-berkala">
                  <Text fontSize="14px" fontWeight="bold">
                    Informasi Berkala
                  </Text>
                </NavLink>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/informasi-setiap-saat">
                  <Text fontSize="14px" fontWeight="bold">
                    Informasi Setiap Saat
                  </Text>
                </NavLink>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/informasi-serta-merta">
                  <Text fontSize="14px" fontWeight="bold">
                    Infromasi Serta Merta
                  </Text>
                </NavLink>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="/informasi-dikecualikan">
                  <Text fontSize="14px" fontWeight="bold">
                    Informasi Dikecualikan
                  </Text>
                </NavLink>
              </Stack> */}
            </Flex>
          </Collapse>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontSize="14px" fontWeight="bold">
              PUBLIKASI
            </Text>
            <ChevronDownIcon w={7} h={7} onClick={onTogglePublikasi} />
          </Stack>
          <Collapse in={openPublikasi} animateOpacity>
            <Flex direction="column" gap="2" ml={4}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="https://spjt.co.id/berita-kegiatan/" target="_blank">
                  <Text fontSize="14px" fontWeight="bold">
                    Berita & Kegiatan
                  </Text>
                </NavLink>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="https://spjt.co.id/pengadaan/" target="_blank">
                  <Text fontSize="14px" fontWeight="bold">
                    Pengadaan Barang & Jasa
                  </Text>
                </NavLink>
              </Stack>
            </Flex>
          </Collapse>
          {/* <Stack direction="row" alignItems="center">
            <NavLink to="/tjslp">
              <Text fontSize="14px" fontWeight="bold">
                TJLSP
              </Text>
            </NavLink>
          </Stack> */}
          <Stack direction="row" alignItems="center">
            <NavLink to="https://spjt.co.id/karir/" target="_blank">
              <Text fontSize="14px" fontWeight="bold">
                KARIR
              </Text>
            </NavLink>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontSize="14px" fontWeight="bold">
              DRM
            </Text>
            <ChevronDownIcon w={7} h={7} onClick={onToggleDrm} />
          </Stack>
          <Collapse in={openDrm} animateOpacity>
            <Flex direction="column" gap="2" ml={4}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <NavLink to="#" target="_blank">
                  <Text fontSize="14px" fontWeight="bold">
                    Daftar Rekanan Mampu (DRM)
                  </Text>
                </NavLink>
              </Stack>
            </Flex>
          </Collapse>

          <Stack direction="row" alignItems="center">
            <NavLink to="/Kontak">
              <Text fontSize="14px" fontWeight="bold">
                KONTAK
              </Text>
            </NavLink>
          </Stack>
        </Stack>
      </Collapse>
    </Box>
  );
}
