import { Box, Button, Center, Container, Flex, Grid, GridItem, Image, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { useSelector } from 'react-redux'
import FadeInSection from '../atoms/MyFade'
import { truncate } from '../utils/helper'

export default function BeritaTerkini() {
  const {beritaReducer} = useSelector(state => state)

  return (
    <Box w="full" p={{ base: "4", md: "16" }} bg="gray.50">
      <FadeInSection>
        <Text fontSize="3xl" fontWeight="bold" textAlign="center" color="gray.700">Berita Terkini</Text>
      </FadeInSection>
      <Container maxW="5xl" mt="10">
        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr" }} gap="10">
          {
            beritaReducer.data.map((beritaTerkini, id) => (
              <GridItem
                rounded="lg"
                overflow="hidden"
                shadow="md"
                minH="350"
                bg="white"
                key={id}>
                <Flex direction="column" gap="2">
                  <Box
                    w="100%"
                    h="200px"
                    overflow="hidden">
                    <Image
                      w="full"
                      h="full"
                      objectFit="fill"
                      src={beritaTerkini.lokasi_gambar}
                      transform="scale(1)"
                      transition="0.8s ease-in-out"
                      _hover={{
                        transform: "scale(1.2)"
                      }} />
                  </Box>
                  <Stack px="2" pb="2" spacing={2}>
                    <Text fontWeight="bold" fontSize="lg">{beritaTerkini.judul}</Text>
                    <Text fontSize="sm" color="gray.600">{truncate(beritaTerkini.content)}</Text>
                    <Button
                      borderWidth="2px"
                      borderStyle="solid"
                      borderColor="red.400"
                      color="red.600"
                      width="fit-content"
                    >Selengkapnya</Button>
                  </Stack>
                </Flex>
              </GridItem>
            ))
          }
        </Grid>
      </Container>
      <Center mt="10">
        <Button onClick={() => window.open('https://spjt.co.id/berita-kegiatan/', '_blank')} bg="#0A3C7E" _hover={{ bg: "#194c8e" }}>
          <Text textAlign="center" color="white">Lihat Semua Berita</Text>
        </Button>
      </Center>
    </Box>
  )
}
