export const INITIAL_DATA = {
  laporanKeuangan: {
    data: [
      {
        title: "Laporan Keuangan - 2022",
        src: "https://spjt.co.id/wp-content/uploads/2023/02/2022-Financial-Statement.pdf",
      },
      {
        title: "Laporan Keuangan - 2021",
        src: "https://spjt.co.id/wp-content/uploads/2023/02/2021-Financial-Statement.pdf",
      },
      {
        title: "Laporan Keuangan - 2020",
        src: "https://spjt.co.id/wp-content/uploads/2023/02/2020-Financial-Statement.pdf",
      },
      {
        title: "Laporan Keuangan - 2019",
        src: "https://spjt.co.id/wp-content/uploads/2023/02/2019-Financial-Statement.pdf",
      },
      {
        title: "Laporan Keuangan - 2018",
        src: "https://spjt.co.id/wp-content/uploads/2023/02/2018-Financial-Statement.pdf",
      },
      {
        title: "Laporan Keuangan - 2016-2017",
        src: "https://spjt.co.id/wp-content/uploads/2023/02/Laporan-Keuangan-2016-2017.jpg",
      },
      {
        title: "Laporan Keuangan - 2015-2016",
        src: "https://spjt.co.id/wp-content/uploads/2023/02/Laporan-Keuangan-2015-2016.jpg",
      },
      {
        title: "Laporan Keuangan - 2014-2015",
        src: "https://spjt.co.id/wp-content/uploads/2023/02/Laporan-Keuangan-2014-2015.jpg",
      },
    ],
  },
  laporanTahunan: {
    data: [
      {
        title: "Laporan Tahunan - 2021",
        src: "https://spjt.co.id/wp-content/uploads/2023/02/Laporan-Tahunan-2021.pdf",
      },
      {
        title: "Laporan Tahunan - 2020",
        src: "https://spjt.co.id/wp-content/uploads/2023/02/Laporan-Tahunan-2020.pdf",
      },
      {
        title: "Laporan Tahunan - 2019",
        src: "https://spjt.co.id/wp-content/uploads/2023/02/Laporan-Tahunan-2019.pdf",
      },
      {
        title: "Laporan Tahunan - 2018",
        src: "https://spjt.co.id/wp-content/uploads/2023/02/Laporan-Tahunan-2018.pdf",
      },
    ],
  },
  informasiBerkala: {
    data: [
      {
        kontentInformasi: "Profil Perseroan",
        penanggungJawab: "Sekretaris Prusahaan",
        pembuatan: "Setiap terdapat perubahan",
        formatTersedia: "Cetak dan digital",
        jangkaWaktu: "4 Tahun",
        keterangan: "Gambaran Umum Perusahaan",
      },
      {
        kontentInformasi: "Ringkasan Laporan Keuangan Tahunan",
        penanggungJawab: "Kadiv Keuangan",
        pembuatan: "Setiap tahun",
        formatTersedia: "Cetak dan online",
        jangkaWaktu: "1 Tahun",
        keterangan:
          "Ringkasan Laporan Keuangan (Laporan Laba Rugi, Laporan Posisi Keuangan dan Neraca)",
      },
      {
        kontentInformasi: "Kegiatan Perseroan",
        penanggungJawab: "Sekretaris Perusahaan",
        pembuatan: "Setiap terdapat perubahan",
        formatTersedia: "Cetak dan online",
        jangkaWaktu: "4 Tahun",
        keterangan: "Ringkasan Laporan Kegiatan Induk dan Anak Perseroan",
      },
      {
        kontentInformasi: "Susunan Pengurus Perseroan",
        penanggungJawab: "Sekretaris Perusahaan",
        pembuatan: "Setiap terdapat perubahan",
        formatTersedia: "Cetak dan digital",
        jangkaWaktu: "Selama berlaku",
        keterangan: "Informasi terbaru susunan pengurus",
      },
      {
        kontentInformasi: "Pengumuman Pengadaan Barang dan Jasa",
        penanggungJawab: "Kadiv SDM & Umum",
        pembuatan:
          "Setiap Pelaksanaan Pengadaan Barang/Jasa (tender) Laporan Pengadaan Barang/Jasa (tender) Daftar Penyedia Mampu (DPM)",
        formatTersedia: "Cetak dan/atau online",
        jangkaWaktu: "Selama berlaku",
        keterangan: "Informasi pengadaan barang dan jasa",
      },
      {
        kontentInformasi: "e-LHKPN",
        penanggungJawab: "Sekretaris Perusahaan",
        pembuatan: "Setiap terdapat perubahan",
        formatTersedia: "Cetak dan online",
        jangkaWaktu: "Selama berlaku",
        keterangan: "Ringkasan laporan harta kekayaan Direksi",
      },
    ],
  },
  informasiSetiapSaat: {
    data: [
      {
        kontenInformasi: "Daftar Informasi Publik Perseroan",
        penanggungJawab: "PPID",
        pembuatan: "Setiap terdapat perubahan",
        formatTersedia: "Cetak dan online",
        jangkaWaktu: "Selama Berlaku",
        keterangan: "Daftar Klasifikasi Informasi Publik",
      },
      {
        kontenInformasi: "Layanan Akses Informasi Publik",
        penanggungJawab: "PPID",
        pembuatan: "Setiap terdapat perubahan",
        formatTersedia: "Cetak dan online",
        jangkaWaktu: "1 Tahun",
        keterangan: "Pekembangan pelayanan publik selama 1 tahun",
      },
    ],
  },
  informasiSertaMerta: {
    data: [
      {
        kontenInformasi: "Informasi Keadaan Darurat",
        penanggungJawab: "PPID",
        pembuatan: "Tahun 2022",
        format: "Cetak dan Online",
        jangkaWaktu: "Selama Berlaku",
        keterangan:
          "Menyampaikan informasi-informasi keadaan darurat bencana alam di lingkungan SPJT Group",
      },
    ],
  },
  informasiDikecualikan: {
    data: [
      {
        kontenInformasi:
          "Rencana Kerja dan Anggaran Perusahaan (RKAP) dan Rencana Jangka Panjang Perusahaan (RJPP)",
        dasarHukum:
          "UU No.14 Th.2008 ttg KIP Pasal 17 huruf b dan j UU No.30 Th.2000 ttg Rahasia Dagang pasal 3 UU No.40 Th.2007 ttg PT ps.63-65 Peraturan Pemerintah RI Nomor 54 Tahun 2017 tentang BUMD Peraturan Menteri Dalam Negeri Republik Indonesia Nomor 118 Tahun 2018 tentang Rencana Bisnis Rencana Kerja dan Anggaran Kerjasama",
        alasanInfromasi:
          "Melindungi perusahaan dari persaingan usaha yang tidak sehat Memiliki nilai ekonomi bagi Perusahaan Melindungi rahasia bisnis Perusahaan",
        batasWaktu: "Sampai dengan Pengesahan dari Pemegang Saham",
        ujiKonsekuensiAkibat:
          "Rahasia perusahaan terbuka Mengganggu kepentingan perlindungan terhadap Hak Kekayaan Intelektual (HAKI)",
        ujiKonsekuensiManfaat:
          "Perusahaan terlindungi dari persaingan usaha yang tidak sehat Perlindungan terhadap HAKI",
      },
    ],
  },
  produkKami: {
    data: [
      {
        name: "Garam",
        src: "https://saranapatra.com/images/garam.webp",
        link: "",
      },
      {
        name: "Jasa Konstruksi",
        src: "https://saranapatra.com/images/jasa_konstruksi.webp",
        link: "",
      },
      {
        name: "Daging",
        src: "https://saranapatra.com/images/daging.webp",
        link: "",
      },
      {
        name: "Beras",
        src: "https://saranapatra.com/images/beras.webp",
        link: "",
      },
    ],
  },
  bisnisKami: {
    data: [
      {
        name: "Pabrik Garam",
        image: "https://saranapatra.com/images/cropped-garam.webp",
        content:
          "Menjual garam krosok/kasar, garam industri/halus, garam konsumsi/dapur yang berkualitas dan halal",
      },
      {
        name: "Pabrik Limbah B3",
        image: "https://saranapatra.com/images/bisnisPabrikLimbah.webp",
        content:
          "Menyediakan jasa pengangkutan & pengolahan limbah B3 medis & non medis yang aman & ramah lingkungan",
      },
      {
        name: "Meat Processing",
        image: "https://saranapatra.com/images/binsisMeatProcessing.webp",
        content:
          "Menjual daging ayam, daging sapi & daging frozen segar yang higienis, halal, aman dan berkualitas",
      },
      {
        name: "Jasa Konstruksi",
        image: "https://saranapatra.com/images/imgJaskon.webp",
        content:
          "Menyediakan layanan jasa konstruksi untuk bangunan gedung rumah sakit, kantor dan sekolah",
      },
      {
        name: "Industri Beras",
        image: "https://saranapatra.com/images/imgBerasGayeng.webp",
        content:
          "Menjual beras premium, aromatic, dan medium berkualitas, berserat tinggi, sehat, dan halal",
      },
    ],
  },
  anakPerusahaan: {
    data: [
      {
        name: "PT. Sarana Patra Jateng",
        image: "https://saranapatra.com/images/client-spj.png",
        content:
          "Anak usaha PT. SPJT yang bergerak di bidang Meat Processing, Transporter Limbah B3, Jasa Retester & Repaint LPG, Pertamina.",
      },
      {
        name: "PT. Jasa Medivest",
        image: "https://saranapatra.com/images/client-jamed.png",
        content:
          "Anak perusahaan BUMD Jawa Barat (PT Jasa Sarana) dan BUMD Jawa Tengah (PT SPJT), yang bergerak di bidang usaha jasa pengelolaan limbah B3 medis di Indonesia.",
      },
      {
        name: "PT. Penggaron Sarana Semesta",
        image: "https://saranapatra.com/images/client-tmj.png",
        content:
          "Merupakan Perusahaan Asosiasi, kerjasama PT. Sarana Pembangunan Jawa Tengah dengan PT. Palawi Risorsis yang bergerak dalam Bidang Pariwisata.",
      },
      {
        name: "PT. Trans Marga Jateng",
        image: "https://saranapatra.com/images/client-tmj.png",
        content:
          "Melaksanakan pengelolaan jalan Tol Semarang - Solo. Sahamnya dimiliki oleh Jasa Marga, Astra Infra, Trans Optima Luhur dan SPJT.",
      },
      {
        name: "PT. Aria Amandaru",
        image: "https://saranapatra.com/images/client-aa.png",
        content:
          "Kyriad Arra Hotel Cepu. Hotel bintang 3 yang terletak di wilayah strategis, Jl. Raya Cepu-Randublatung Km 2 Joho, Mulyorejo, Cepu, Blora, menggabungkan MICE dan rekreasi dalam satu lingkungan",
      },
      {
        name: "PT. Astari Marga Sarana",
        image: "https://saranapatra.com/images/icAMSRestaPendopo456.png",
        content:
          "Sinergi bisnis PT. SPJT dengan PT. Astra Astari Sejahtera dalam bidang real estate, mempersembahkan RESTA PENDOPO 456 sebagai tempat Istirahat dan Pelayanan dengan konsep transit destination.",
      },
    ],
  },
  galeriVideo: {
    data: [
      {
        link: "https://www.youtube.com/watch?v=jqhK8ZN4KwY",
      },
      {
        link: "https://www.youtube.com/watch?v=J7EFlO24MOc",
      },
      {
        link: "https://www.youtube.com/watch?v=34gxoxnosYc",
      },
      {
        link: "https://www.youtube.com/watch?v=ivkyt-mcjN8",
      },
    ],
  },
  beritaTerkini: {
    data: [
      {
        name: "PT. Sarana Patra Jateng",
        content:
          "Anak usaha PT. SPJT yang bergerak di bidang Meat Processing, Transporter Limbah B3, Jasa Retester & Repaint LPG, Pertamina.",
        image: "https://saranapatra.com/images/berita-image-1.jpg",
      },
      {
        name: "[Lowongan Kerja] Supervisor Marketing Unit Usaha",
        content:
          "Supervisor Marketing Unit Usaha Perdagangan Daging Pendidikan min. S1 Semua Jurusan (S1 Peternakan lebih diutamakan);Memiliki pengalaman min. 5 tahun sebagai sales/marketing di bidang perdagangan...",
        image: "https://saranapatra.com/images/karir.webp",
      },
      {
        name: "[Lowongan Kerja] Supervisor Marketing Unit Transporter",
        content:
          "Supervisor Marketing Unit Transporter Limbah B3 Pendidikan min. S1 Semua Jurusan;Memiliki pengalaman min. 5 tahun sebagai sales/marketing/medical representative;Memiliki kemampuan membuat strategic ...",
        image: "https://saranapatra.com/images/karir.webp",
      },
    ],
  },
  mediaSosial: {
    data: [{}, {}, {}, {}],
  },
  dataInfrastruktur: {
    data: [
      {
        image: "https://saranapatra.com/images/imgGedungPerkantoran.jpg",
        title: "Konstruksi Perkantoran",
        body: "Mencakup usaha pembangunan, pemeliharaan, dan/atau pembangunan kembali bangunan yang dipergunakan untuk perkantoran, seperti kantor dan rumah kantor (rukan).",
      },
      {
        image: "https://saranapatra.com/images/imgGedungKesehatan.jpg",
        title: "Konstruksi Gedung Kesehatan",
        body: "Pembangunan, pemeliharaan, dan/atau pembangunan kembali bangunan yang digunakan untuk sarana kesehatan, seperti rumah sakit, poliklinik, puskesmas, balai pengobatan, gedung pelayanan kesehatan dan gedung laboratorium. Termasuk kegiatan perubahan dan renovasi gedung kesehatan.",
      },
      {
        image: "https://saranapatra.com/images/imgGedungSekolah.jpg",
        title: "Konstruksi Gedung Pendidikan",
        body: "Meliputi usaha pembangunan, pemeliharaan, dan/atau pembangunan kembali bangunan yang digunakan untuk sarana pendidikan, seperti gedung sekolah, tempat kursus, laboratorium dan bangunan penunjang pendidikan lainnya. Termasuk kegiatan perubahan dan renovasi gedung pendidikan.",
      },
    ],
  },
  dataManufaktur: {
    data: [
      // {
      //   image: "https://saranapatra.com/images/imgBerasGayeng.jpg",
      //   title: "Beras Gayeng",
      //   body: "Bersumber dari Petani lokal di Jawa Tengah dengan proses pemilihan bahan baku yang ketat untuk kualitas terbaik, penggunaan teknologi modern dan proses yang higienis.",
      // },
      // {
      //   image: "https://saranapatra.com/images/imgGaramGayeng.jpg",
      //   title: "Garam Gayeng",
      //   body: "Garam Gayeng berkualitas tinggi dari hasil petambak garam lokal, dengan sumber bahan baku hasil petambak terbaik di wilayah pantai Jawa Tengah.",
      // },
      {
        image: "https://saranapatra.com/images/meat1.jpg",
        title: "Meat Processing",
        body: "Industri Pengolahan daging ayam, daging sapi & daging frozen segar yang higienis, halal, aman, berasal dari peternakan terbaik dan berkualitas serta bersertifikat halal MUI",
      },
    ],
  },
  profilPerusahaan: {
    perindustrian: {
      data: [
        {
          name: "Pabrik Garam Industri",
          content:
            "Sinergi dengan Petani Garam Rakyat, SPJT menjalankan usaha pengolahan garam krosok menjadi Garam Industri dengan kadar NACL minimum 97% Terletak di Desa Raci, Kecamatan Batangan, Kabupaten Pati, Pabrik akan beroperasi memenuhi kebutuhan bahan baku garam industry dengan kualitas terbaiknya.",
          image: "https://saranapatra.com/images/cropped-garam.jpg",
        },
      ],
    },
    jasa: {
      data: [
        {
          name: "Retester Tabung LPG 3KG",
          body: "Retester tabung LPG 3kg adalah proses pengujian ulang atau inspeksi berkala terhadap tabung gas elpiji ukuran 3kg, yang bertujuan untuk memastikan keselamatan dan kualitas tabung gas tersebut sebelum digunakan kembali. Retester tabung LPG 3kg meliputi pemeriksaan visual, pengukuran ketebalan dinding tabung, dan pengujian tekanan untuk memastikan tabung gas masih aman untuk digunakan.",
          image: "https://saranapatra.com/images/imgRetester-1.jpg",
        },
        {
          name: "Transporter Limbah B3",
          body: "Kami menyediakan jasa pengangkutan limbah B3 medis & B3 industri dengan armada dan pengemudi terlatih bersertifikat dengan legalitas perijinan yang lengkap",
          image: "https://saranapatra.com/images/transporter-image.jpg",
        },
        {
          name: "Meat Processing",
          body: "Industri Pengolahan daging ayam, daging sapi & daging frozen segar yang higienis, halal, aman, berasal dari peternakan terbaik dan berkualitas serta bersertifikat halal MUI.",
          image: "https://saranapatra.com/images/meat1.jpg",
        },
      ],
    },
    nilaiPerusahaan: {
      data: [
        {
          name: "Spirit Of Winning",
          content:
            "Semangat Kemenangan: berusaha keras untuk melakukan yang terbaik penuh inovasi dan tidak pernah menyerah.",
        },
        {
          name: "Partnership",
          content:
            "Kemitraan: Membangun hubungan yang tulus dan saling membantu. Kami percaya bahwa hubungan yang baik dengan perusahaan yang memiliki nilai-nilai yang sama akan membantu pertumbuhan kedua belah pihak.",
        },
        {
          name: "Joyful",
          content:
            "Suka Cita: Melakukan segala sesuatu dengan gembira untuk memberikan kinerja terbaik dan hasil yang luar biasa.",
        },
        {
          name: "Trustworthy",
          content:
            "Dapat Dipercaya: Transparan, Jujur dan berintegritas dalam segala hal.",
        },
      ],
    },
  },
  penghargaan: {
    daftarPenghargaan: {
      data: [
        {
          name: "SPJT Raih Penghargaan CSR AWARD 2022",
          content:
            "SPJT Raih Penghargaan CSR AWARD 2022 Kategori BUMD Penghargaan diberikan sebagai bentuk apresiasi yang diberikan oleh Pemerintah Provinsi Jawa Tengah atas kontribusi sosial yang dilaksanakan sekaligus amanah agar SPJT senantiasa memegang teguh komitmen terhadap peran tanggung jawab sosialnya serta merupakan suatu kebanggaan bagi Perusahaan atas upaya-upaya yang telah dilaksanakan untuk masyarakat dan lingkungan sekitar.",
          tanggal: "28 December 2022",
          image: "https://saranapatra.com/images/CSR_Awards-300x200.png",
        },
        {
          name: "Sertifikasi ISO 9001:2015 dan 14001:2015",
          content:
            "Sertifikasi ISO 9001:2015 dan 14001:2015 Komitmen terhadap kualitas dan mutu produk yang berorientasi pada layanan Pelanggan dan konsen terhadap dampak terhadap lingkungan yang ditimbulkan oleh aktivitas Unit Usaha Retester PT. Sarana Patra Jateng sebagai salah satu Anak Perusahaan PT. Sarana Pembangunan Jawa Tengah telah meraih Penghargaan dalam bentuk Sertifikasi ISO 9001 : 2015 dan ISO",
          tanggal: "21 April 2021",
          image: "https://saranapatra.com/images/ISOSPJ-300x225.png",
        },
      ],
    },
  },
  dewanKomisaris: {
    daftarDewanKomisaris: {
      data: [
        {
          name: "Hadi Wiyono",
          jabatan: "Direktur",
          gambar: "https://saranapatra.com/images/direktur-hadi-new.png",
          content:
            "Lahir di Kota Semarang pada tahun 1977, diangkat sebagai Direktur PT. SPJ tanggal 12 Juni 2022, bergabung dengan SPJT Grup dari tahun 2007 dengan perjalanan karir yaitu memegang tugas tanggung jawab dibidang Procurement, IT Business Development, dan beberapa kali melaksanakan penugasan di Anak Perusahaan maupun Asosiasi yaitu PT.Trans Marga Jateng, PT. Sarana Karya Jateng dan saat ini di PT. Sarana Patra Jateng. Menyelesaikan pendidikan di Fakultas Ilmu Komputer Universitas Dian Nuswantoro",
        },
        {
          name: "Eko Budi Utomo",
          jabatan: "Komisaris",
          gambar: "https://saranapatra.com/images/komisaris-eko-new.png",
          content:
            "Lahir di Demak Jawa Tengah. Memperoleh gelar Sarjana (S1) Ekonomi di bidang Manajemen Keuangan, Universitas Diponegoro. Saat ini juga menjabat sebagai Plt. Kepala Divisi Pengembangan Bisnis PT. SPJT. Sebelumnya berkarir di Perbankan sebagai Pemimpin Cabang Utama Bank BRI (2019), Pemimpin Cabang Pembantu Bank BRI (2013-2019), Manajer Pemasaran Bank BRI (2013) & Corporate Account Manager Head Office Bank BRI (2009-2013).",
        },
      ],
    },
  },
  berita: {
    data: [
      {
        gambar: '',
        title: 'Silaturahmi Idul Fitri 1444H/ 2023M PT. Sarana Pembangunan Jawa Tengah',
        categori: 'Kegiatan',
        deskripsi: 'SPJT menyelenggarakan Silaturahmi Idul Fitri 1444 H pada hari pertama masuk kerja, Rabu, 26 April 2023. Acara dihadiri oleh Dewan Komisaris, Direksi serta seluruh karyawan PT. SPJT dan PT. SPJ (Anak Usaha PT. SPJT).Bp. Untung Juanto, ST, MM, Direktur Utama PT. SPJT menyampaikan agar kita semua menjaga kekompakan dan kekeluargaan sebagai satu Tim, menumbuhkan semangat baru untuk mencapai kinerja yang profesional dan berintegritas dalam rangka mencapai target yang telah ditetapkan dalam Rencana Kerja Anggaran Perusahaan (RKAP).Pada sambutannya Bp. Sarwa Pramana, SH, M.Si, Komisaris Utama PT. SPJT menyampaikan permohonan maaf apabila ada tindakan yang dirasa kurang berkenan, selanjutnya Bp. Sarwa Pramana meminta kepada Seluruh Karyawan SPJT Group untuk memiliki energi baru dalam menjalankan aktivitas kerja setelah menjalani Ibadah Ramadan serta menyampaikan terima kasih kepada Jajaran Direksi atas capaian progres yang cukup menggembirakan dalam kurun waktu 4 (empat) bulan menjabat. Beliau juga menyampaikan pesan agar 2 (dua) proyek utama PT. SPJT yaitu pembangunan Pabrik garam Pati dan pabrik pengolahan limbah B3 untuk dapat segera direalisasikan.Acara tersebut diisi dengan saling bermaafan antara Manajemen dan Karyawan SPJT Group dan Doa Bersama untuk kinerja PT. SPJT yang unggul dan berkualitas.'
      },
      {
        gambar: '',
        title: ''
      }
    ]
  }
};

// const base_url = "http://localhost:8080";
const base_url = "https://admin.saranapatra.com";

export const getSliders = async () => {
  const response = await fetch(`${base_url}/api/v1/homepage/sliders`);
  const responseJson = await response.json();

  return responseJson.data;
};

export const getProdukKami = async () => {
  const response = await fetch(`${base_url}/api/v1/homepage/produk-kami`);
  const responseJson = await response.json();

  return responseJson.data;
};

export const getBisnisKami = async () => {
  const response = await fetch(`${base_url}/api/v1/homepage/bisnis-kami`);
  const responseJson = await response.json();

  return responseJson.data;
};

export const getAnakPerusahaan = async () => {
  const response = await fetch(`${base_url}/api/v1/homepage/anak-perusahaan`);
  const responseJson = await response.json();

  return responseJson.data;
};

export const getSertifikat = async () => {
  const response = await fetch(`${base_url}/api/v1/tentang-spj/penghargaan`);
  const responseJson = await response.json();

  return responseJson.data;
};

export const getDewanKomisaris = async () => {
  const response = await fetch(`${base_url}/api/v1/tentang-spj/dewan-komisaris`);
  const responseJson = await response.json()

  return responseJson.data
}

export const getGaleri = async () => {
  const response = await fetch(`${base_url}/api/v1/homepage/galeri`);
  const responseJson = await response.json()

  return responseJson.data
}

export const getBerita = async () => {
  const response = await fetch(`${base_url}/api/v1/homepage/berita`);
  const responseJson = await response.json()

  return responseJson.data
}