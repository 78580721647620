import React from "react";
import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { INITIAL_DATA } from "../../../utils/INITIAL_DATA";

export default function LatarBelakang({ refLatarBelakang }) {
  return (
    <>
      <Box bg="gray.50" py="30" ref={refLatarBelakang}>
        <Container maxW="6xl">
          <Text fontSize="30" fontWeight="bold" color="#0A3C7E">
            Latar Belakang
          </Text>
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            gap={{ base: "5" }}
            mt="5"
          >
            <GridItem>
              <Stack
                spacing="5"
                alignItems="center"
                justifyContent="center"
                h="full"
              >
                <Text lineHeight="tall">
                  PT Sarana Patra Jateng merupakan anak Perusahaan dari PT
                  Sarana Pembangunan Jawa Tengah (SPJT) Badan Usaha Milik Daerah
                  (BUMD) pemerintah provinsi Jawa Tengah yang didirikan pada
                  Tahun 2008 dan telah berbadan hukum dengan Akta Pendirian
                  Nomor : 04 tanggal 01 Pebruari 2008 dengan notaris Prof. DR.
                  Liliana Tedjosaputro, SH., MH., MM.
                </Text>
                <Text>
                  Sebagai anak perusahaan Badan Usaha Milik Daerah (BUMD), PT
                  Sarana Patra Jateng tentunya ingin lebih dekat dan dikenal,
                  baik oleh masyarakat, pengusaha, investor maupun perusahaan
                  lain, baik itu perusahaan pemerintah maupun swasta. Untuk itu
                  guna memberikan informasi yang ingin diberikan kepada konsumen
                  maupun masyarakat yang mungkin ingin lebih tahu mengenai PT.
                  Sarana Patra Jateng, maka dibuatlah sebuah company profile
                  yang didalamnya berisikan data data mengenai perusahaan dan
                  usaha–usaha perusahaan yang dijalankan.
                </Text>
              </Stack>
            </GridItem>
            <GridItem>
              <Flex
                p="5"
                h="full"
                gap="5"
                alignItems="center"
                justifyContent="center"
                flexWrap="wrap"
              >
                <Flex
                  direction="column"
                  w={{ base: "100%", sm: "40%" }}
                  justify="center"
                  h="200"
                  borderRadius="10"
                >
                  <Image
                    width="200px"
                    height="200px"
                    src="https://saranapatra.com/images/spjt.png"
                    alignSelf="center"
                  />
                  <Stack
                    justifyContent="flex-end"
                    spacing="0"
                    alignItems="center"
                  >
                    <Text
                      color="#0A3C7E"
                      textAlign="center"
                      fontSize="2xl"
                      fontWeight="bold"
                    >
                      99.73%
                    </Text>
                    <Text textAlign="center" fontWeight="bold" fontSize="small">
                      PT Sarana Pembangunan Jawa Tengah
                    </Text>
                  </Stack>
                </Flex>
                <Flex
                  direction="column"
                  w={{ base: "100%", sm: "40%" }}
                  justify="center"
                  h="200"
                  borderRadius="10"
                >
                  <Image
                    width="132px"
                    height="132px"
                    src="https://saranapatra.com/images/lki.jpg"
                    alignSelf="center"
                  />
                  <Stack
                    justifyContent="flex-end"
                    spacing="0"
                    alignItems="center"
                  >
                    <Text
                      color="#0A3C7E"
                      textAlign="center"
                      fontSize="2xl"
                      fontWeight="bold"
                    >
                      0.27%
                    </Text>
                    <Text textAlign="center" fontWeight="bold" fontSize="small">
                      Koperasi Serba Usaha Bumi Kencana
                    </Text>
                  </Stack>
                </Flex>
              </Flex>
            </GridItem>
          </Grid>

          {/* <Stack mt="14" ml="3" spacing="5">
            <Text fontSize="24" fontWeight="bold">
              Infrastruktur
            </Text>
            <Text>
              Sesuai dengan Namanya Sarana Patra Jateng, SPJ hadir
              mengembangkan bisnis Layanan Jasa:
            </Text>
            <Stack>
              <Grid
                templateColumns={{
                  base: "1fr",
                  md: "1fr 1fr",
                  lg: "1fr 1fr 1fr",
                }}
                gap="5"
              >
                {INITIAL_DATA.dataInfrastruktur.data.map(
                  (infrastruktur, id) => (
                    <CardInfrastrktur
                      key={id}
                      image={`${infrastruktur.image}`}
                      title={`${infrastruktur.title}`}
                      body={`${infrastruktur.body}`}
                    />
                  )
                )}
              </Grid>
            </Stack>
          </Stack> */}

          <Stack mt="14" ml="3" spacing="5">
            <Text fontSize="24" fontWeight="bold">
              Industri
            </Text>
            <Text>
              Beberapa produk berkualitas disediakan oleh PT Sarana Patra Jateng yang dapat
              dinikmati bersama orang-orang terkasih
            </Text>
            <Stack>
              <Grid
                templateColumns={{
                  base: "1fr",
                  md: "1fr 1fr",
                  lg: "1fr 1fr 1fr",
                }}
                gap="5"
              >
                {/* {INITIAL_DATA.dataManufaktur.data.map((infrastruktur, id) => (
                  <CardInfrastrktur
                    key={id}
                    image={`${infrastruktur.image}`}
                    title={infrastruktur.title}
                    body={infrastruktur.body}
                  />
                ))} */}
              </Grid>
            </Stack>
          </Stack>

          {/* <Stack mt="14" ml="3" spacing="5">
            <Text fontSize="24" fontWeight="bold">
              Perindustrian
            </Text>
            <Stack w="full">
              {INITIAL_DATA.profilPerusahaan.perindustrian.data.map(
                (perindustrian, id) => (
                  <Flex
                    key={id}
                    gap="3"
                    w="full"
                    direction={{ base: "column", md: "row" }}
                    shadow="md"
                    borderRadius="10"
                    overflow="hidden"
                  >
                    <Box width={{ base: "100%", md: "20%" }} height="100%">
                      <Image
                        src={perindustrian.image}
                        width="100%"
                        height="100%"
                      />
                    </Box>
                    <Box p="5" flex="1">
                      <Stack>
                        <Text fontWeight="bold" fontSize="xl">
                          {perindustrian.name}
                        </Text>
                        <Text>{perindustrian.content}</Text>
                      </Stack>
                    </Box>
                  </Flex>
                )
              )}
            </Stack>
          </Stack> */}

          <Stack ml="3" spacing="5">
            <Stack w="full" spacing="5">
              {INITIAL_DATA.profilPerusahaan.jasa.data.map(
                (profilPerusahaan, id) => (
                  <Flex
                    key={id}
                    gap="3"
                    w="full"
                    direction={{ base: "column", md: "row" }}
                    shadow="md"
                    borderRadius="10"
                    overflow="hidden"
                  >
                    <Box width={{ base: "100%", md: "20%" }} height="100%">
                      <Image
                        src={profilPerusahaan.image}
                        width="100%"
                        height="100%"
                      />
                    </Box>
                    <Box p="5" flex="1">
                      <Stack>
                        <Text fontWeight="bold" fontSize="xl">
                          {profilPerusahaan.name}
                        </Text>
                        <Text>{profilPerusahaan.body}</Text>
                      </Stack>
                    </Box>
                  </Flex>
                )
              )}
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
