const { createSlice } = require("@reduxjs/toolkit");

const anakPerusahaanSlice = createSlice({
    name: 'anakPerusahaan',
    initialState: {
        data: []
    },
    reducers: {
        setSuccessData(state, action) {
            state.data = action.payload
        }
    }
})


export const anakPerusahaanReducer = anakPerusahaanSlice.reducer
export const anakPerusahaanAction = anakPerusahaanSlice.actions