import React from 'react'
import { Box, Grid, GridItem, Stack, Text, Flex, Button } from "@chakra-ui/react";
import FadeInSection from '../atoms/MyFade';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';

export default function TentangKami() {
  const navigate = useNavigate()

  return (
    <Box w="full" p={{ base: "4", md: "16" }} bg="gray.50">
      <Grid templateColumns={{ base: "1fr", lg: "1fr 1fr" }} gap="10">
        <GridItem>
          <Flex direction="column" gap="6">
            <FadeInSection>
              <Text fontSize="30" fontWeight="extrabold">Tentang PT Sarana Patra Jateng</Text>
            </FadeInSection>
            <Stack>
              <Text>PT Sarana Patra Jateng merupakan anak Perusahaan dari PT Sarana Pembangunan Jawa Tengah (SPJT) Badan Usaha Milik Daerah (BUMD) pemerintah provinsi Jawa Tengah yang didirikan pada Tahun 2008 dan telah berbadan hukum dengan Akta Pendirian Nomor : 04 tanggal 01 Pebruari 2008 dengan notaris Prof. DR. Liliana Tedjosaputro, SH., MH., MM.</Text>
            </Stack>
            <Button
              onClick={() => navigate('/profil-perusahaan')}
              w="fit-content"
              bg="#0A3C7E"
              _hover={{ bg: "orange.400" }}>
              <Text fontWeight="bold" color="white">Selengkapnya</Text>
            </Button>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex w="full" h="full" flexDirection="column" justifyContent="center" alignItems="center">
            {/* <AspectRatio ratio={16 / 8} borderRadius="15" overflow="hidden">
              <iframe
                src="https://www.youtube.com/embed/Lk2xIr1U0eE"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen />
            </AspectRatio> */}
            <Box w="70%" overflow="hidden" h="300" bg="gray.100" rounded="lg" shadow="lg">
              <ReactPlayer width="100%" height="100%" url='https://www.youtube.com/watch?v=Lk2xIr1U0eE' controls={false} light={true} />
            </Box>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  )
}
