import React, { useEffect } from "react";
import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

export default function InformasiPemegangSaham() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box mt="65px">
      <Box w="full" h="80" position="relative">
        <Image src="https://saranapatra.com/images/bgBanner.jpg" w="full" h="full" />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(10, 60, 126, 0.9)"
        >
          <Stack w="full" h="full" justifyContent="center" alignItems="center">
            <Stack justifyContent="center" alignItems="center" gap="4">
              <Text fontSize="4xl" fontWeight="bold" color="white" textAlign="center">
                Informasi Pemegang Saham
              </Text>
              {/* <Stack direction={{ base: "column", md: "row" }} gap={{ base: 0, md: "4" }}>
              </Stack> */}
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box bg="gray.50" py="30">
        <Container maxW="6xl">
          <Flex gap="5" direction="column">
            <Text fontSize="30" fontWeight="bold" color="#0A3C7E">
              Pemegang Saham PT Sarana Patra Jateng
            </Text>
            <Text>
              PT Sarana Pembangunan Jawa Tengah sebagai Pemegang Saham Mayoritas
              PT Sarana Patra Jateng, dengan kepemilikan saham sebesar 99,73% dan Pemegang
              Saham Lainnya Perseroan adalah Koperasi Serba Usaha Bumi Kencana
              Dinas ESDM Provinsi Jawa Tengah dengan kepemilikan saham sebesar
              0,27%.
            </Text>
          </Flex>
          <Grid
            gap="5"
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            mt="10"
          >
            <GridItem p="5">
              <Stack h="full" justifyContent="center" alignItems="center">
                <Image src="https://saranapatra.com/images/spjt.png" />
                <Stack>
                  <Text
                    fontWeight="bold"
                    fontSize="3xl"
                    textAlign="center"
                    color="#0A3C7E"
                  >
                    99.73%
                  </Text>
                  <Text fontWeight="bold" fontSize="2xl" textAlign="center">
                    PT Sarana Pembangunan Jawa Tengah
                  </Text>
                </Stack>
              </Stack>
            </GridItem>
            <GridItem p="5">
              <Stack h="full" justifyContent="center" alignItems="center">
                <Image src="https://saranapatra.com/images/lki.jpg" w="160px" />
                <Stack>
                  <Text
                    fontWeight="bold"
                    fontSize="3xl"
                    textAlign="center"
                    color="#0A3C7E"
                  >
                    0.27%
                  </Text>
                  <Text fontWeight="bold" fontSize="2xl" textAlign="center">
                    Koperasi Serba Usaha Bumi Kencana
                  </Text>
                </Stack>
              </Stack>
            </GridItem>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
