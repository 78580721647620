import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { Box } from "@chakra-ui/react";
import { useState } from "react";

export default function FadeInSection(props) {
  const [ref, inView] = useInView({ threshold: 0.2 });
  const [hasAnimated, setHasAnimated] = useState(false)

  const fadeProps = useSpring({
    opacity: hasAnimated || inView ? 1 : 0,
    transform: hasAnimated || inView ? "translateY(0px)" : "translateY(50px)",
    config: { duration: 500 },
    onRest: () => {
      setHasAnimated(true)
    }
  });

  return (
    <Box ref={ref}>
      <animated.div style={fadeProps}>
        {props.children}
      </animated.div>
    </Box>
  );
}
