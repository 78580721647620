import { Box, Button, GridItem, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";

export default function CardBerita() {
  return (
    <GridItem p="3" bg="#123075" borderRadius="10">
      <Stack spacing="5">
        <Box overflow="hidden">
          <Image
            width="full"
            transition="0.3s ease-in-out"
            src="https://saranapatra.com/images/rupslb_rkap2023-300x200.png"
            borderRadius="10"
            _hover={{ transform: "scale(1.2)" }}
          />
        </Box>
        <Stack>
          <Text color="white" fontWeight="bold">
            RUPSLB RJPP & RKAP 2023 SPJT Optimis Realisasikan Rencana…
          </Text>
          <Button>Selengkapnya</Button>
        </Stack>
      </Stack>
    </GridItem>
  );
}
