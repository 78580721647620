import { getDewanKomisaris } from "../../utils/INITIAL_DATA"
import { dewanKomisarisAction } from "./slice"

export const asyncReceiveDewanKomisaris = () => async (dispatch) => {
    dispatch(dewanKomisarisAction.setInitialData())
    try {
        const dewanKomisaris = await getDewanKomisaris()
        dispatch(dewanKomisarisAction.setSuccessData(dewanKomisaris))
    }catch(error) {
        dispatch(dewanKomisarisAction.setErrorData(error.message))
    }
}