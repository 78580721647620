import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineWhatsApp, AiOutlineArrowRight } from "react-icons/ai";

export default function ButtonWhatsapp() {
  const [typeChat, setTypeChat] = useState("");

  const onSendChatWhatsapp = (message) => {
    const phoneNumber = "089616099449";
    const text = encodeURIComponent(message);

    const url = `https://wa.me/${phoneNumber}?text=${text}`;

    window.open(url, "_blank");
  };

  return (
    <Flex
      position="fixed"
      zIndex={999}
      bottom={{ base: "20", md: "70" }}
      right="5"
    >
      <Popover onClose={() => setTypeChat("")}>
        <PopoverTrigger>
          <Flex
            w="55px"
            h="55px"
            bg="#25d366"
            rounded="full"
            cursor="pointer"
            justifyContent="center"
            alignItems="center"
            _hover={{ bg: "#2be370" }}
            _active={{ bg: "#25d366" }}
          >
            <AiOutlineWhatsApp color="white" fontSize="40" />
          </Flex>
        </PopoverTrigger>
        <PopoverContent
          mb={5}
          mr={3}
          border="none"
          shadow="xl"
          rounded="md"
          overflow="hidden"
          bg="gray.200"
        >
          <PopoverCloseButton color="white" />
          <PopoverHeader bg="#25d366">
            <Flex direction="row" w="full" gap="3">
              <Box w="10" h="10" bg="white" rounded="full" overflow="hidden">
                <Image src="https://saranapatra.com/images/icon.png" width="100%" height="100%" />
              </Box>
              <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Text fontSize="md" fontWeight="bold" color="white">
                  PT. Sarana Patra Jateng
                </Text>
              </Flex>
            </Flex>
          </PopoverHeader>
          <PopoverBody>
            <Flex w="full" h="100" gap="5" flexDirection="column">
              <Box bg="white" width="200px" p="2" shadow="md" rounded="xl">
                <Text fontSize="sm" color="gray.600">
                  Hi kak, Apakah ada yang bisa saya bantu ?
                </Text>
              </Box>
            </Flex>
          </PopoverBody>
          <PopoverHeader bg="white">
            <Flex direction="row" w="full" gap="3">
              <Input
                fontSize="sm"
                color="gray.600"
                onChange={(e) => setTypeChat(e.target.value)}
                value={typeChat}
              />
              <Button bg="#25d366" _hover={{ bg: "#29e66e", color: "white" }}>
                <AiOutlineArrowRight
                  size="20"
                  color="white"
                  onClick={() => onSendChatWhatsapp(typeChat)}
                />
              </Button>
            </Flex>
          </PopoverHeader>
        </PopoverContent>
      </Popover>
    </Flex>
  );
}
