import { getSertifikat } from "../../utils/INITIAL_DATA"
import { sertifikasiAction } from "./slice"

export const asyncReceiveSertifikat = () => async (dispatch) => {
    dispatch(sertifikasiAction.setInitialData())

    try {
        const sertifikat = await getSertifikat()
        
        dispatch(sertifikasiAction.setSuccessData(sertifikat))
    }catch(error) {

    }
}