import { Box, Button, Center, Text } from '@chakra-ui/react'
import React from 'react'
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';

import { Swiper, SwiperSlide } from "swiper/react";
import FadeInSection from '../atoms/MyFade';

export default function GaleriVideo() {
  const {galeriReducer} = useSelector(state => state)

  return (
    <Box w="full" p={{ base: "4", md: "16" }} bg="#0A3C7E">
      <FadeInSection>
        <Text fontSize="3xl" fontWeight="bold" textAlign="center" color="white">Galeri Video</Text>
      </FadeInSection>
      <Box overflow="hidden">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: true
          }}
          className="mySwiper3"
          breakpoints={{
            576: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            1276: {
              slidesPerView: 4,
              spaceBetween: 40
            }
          }}
        >
          {
            galeriReducer.data.map((galeriVideo, id) => (
              <SwiperSlide key={id}>
                <ReactPlayer width="100%" height="100%" url={galeriVideo.link} controls={false} light={true} />
              </SwiperSlide>
            ))
          }
        </Swiper>
      </Box>
      <Center mt="10">
        <Button onClick={() => window.open('https://www.youtube.com/@spjtofficial', '_blank')} bg="yellow" _hover={{ bg: "yellow" }}>
          <Text textAlign="center" color="black" fontSize={{ base: "sm" }}>Kunjungi Channel Youtube</Text>
        </Button>
      </Center>
    </Box>
  )
}
