import React, { useEffect } from "react";
import { Box, Container, Grid, Image, Stack, Text } from "@chakra-ui/react";
import CardBerita from "../atoms/CardBerita";

export default function BeritaDanKegiatan() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box bg="gray.50" mt="65" minH="100vh">
      <Box w="full" h="80" position="relative">
        <Image src="https://saranapatra.com/images/bgBanner.jpg" w="full" h="full" />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(10, 60, 126, 0.9)">
          <Stack w="full" h="full" justifyContent="center" alignItems="center">
            <Stack justifyContent="center" alignItems="center" gap="4">
              <Text fontSize="4xl" fontWeight="bold" color="white">
                Berita & Kegiatan
              </Text>
              {/* <Stack direction={{ base: "column", md: "row" }} gap={{ base: 0, md: "4" }}>
              </Stack> */}
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Container maxW="6xl" py="30">
        <Grid
          templateColumns={{
            base: "1fr",
            md: "1fr 1fr",
            lg: "1fr 1fr 1fr",
            xl: "1fr 1fr 1fr 1fr",
          }}
          gap={{ base: "5" }}
        >
          <CardBerita />
          <CardBerita />
          <CardBerita />
          <CardBerita />
          <CardBerita />
          <CardBerita />
          <CardBerita />
          <CardBerita />
        </Grid>
      </Container>
    </Box>
  );
}
