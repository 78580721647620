import React, { useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  GridItem,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

export default function WhistleBlowingSystem() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box mt="65px">
      <Box w="full" h="80" position="relative">
        <Image src="https://saranapatra.com/images/bgBanner.jpg" w="full" h="full" />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(10, 60, 126, 0.9)"
        >
          <Stack w="full" h="full" justifyContent="center" alignItems="center">
            <Stack justifyContent="center" alignItems="center" gap="4">
              <Text fontSize="4xl" fontWeight="bold" color="white" textAlign="center">
                Whistle Blowing System
              </Text>
              {/* <Stack direction={{ base: "column", md: "row" }} gap={{ base: 0, md: "4" }}>
              </Stack> */}
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box bg="gray.50" py="30">
        <Container maxW="6xl">
          <Grid templateColumns={{ base: "1fr", md: "400px 1fr" }} gap="10">
            <GridItem>
              <Box
                borderWidth="1px"
                borderStyle="solid"
                borderColor="gray.300"
                overflow="hidden"
                borderRadius="10"
              >
                <Image
                  src="https://saranapatra.com/images/wbs-apps-1024x739.png"
                  mx={{ base: "auto" }}
                />
              </Box>
            </GridItem>
            <GridItem>
              <Stack spacing="4">
                <Text fontSize="2xl" color="#0A3C7E" fontWeight="bold">
                  Laporan Dugaan Pelanggaran di Lingkungan PT Sarana Patra Jateng
                </Text>
                <Stack spacing="5">
                  <Text>
                    Salah satu upaya Perusahaan dalam mewujudkan Good Corporate
                    Governance dengan menyediakan sarana pengaduan masalah
                    (whistle blower) dan hot line. Pemangku kepentingan dapat
                    menyampaikan pelaporan atas pelanggaran Code of Conduct
                    melalui induk perusahaan PT Sarana Pembangunan Jawa Tengah:
                  </Text>
                  <Stack spacing="3">
                    <Text>Telepon: +624 76632465/66</Text>
                    <Text>Whatsapp: +62 812 8014 7979</Text>
                    <Text>Email: spjt@spjt.co.id</Text>
                    <Text>Website: www.spjt.co.id</Text>
                  </Stack>
                  <Text>
                    Perusahaan memberikan perlindungan identitas Pelapor atas
                    laporan yang dapat dipertanggungjawabkan. Pengelolaan WBS
                    dilaksanakan dengan prinsip rahasia, anonim dan independent.
                    Setiap laporan yang masuk akan dianalisa terlebih dahulu dan
                    dilakukan klarifikasi kepada Pelapor untuk selanjutnya
                    diproses sesuai ketentuan yang berlaku.
                  </Text>
                </Stack>
              </Stack>
            </GridItem>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
