export const getYear = () => {
    const tanggalan = new Date()

    return tanggalan.getUTCFullYear()
}

export const formatDate = (date) => {
    const dataDate = new Date(date)

    const monts = [
        'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
    ]

    const day = dataDate.getDate();
    const montIndex = dataDate.getMonth();
    const year = dataDate.getFullYear();

    return `${day} ${monts[montIndex]} ${year}`
}

export const truncate = (str) => {
    return `${str.slice(0, 100)}...`
}