import { Stack} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { RotatingLines } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import ButtonWhatsapp from '../atoms/ButtonWhatsapp'
import BeritaTerkini from '../components/BeritaTerkini'
import BisnisKami from '../components/BisnisKami'
import CarrouselHero from '../components/CarrouselHero'
import GaleriVideo from '../components/GaleriVideo'
import MediaSosial from '../components/MediaSosial'
import ProdukKami from '../components/ProdukKami'
import TentangKami from '../components/TentangKami'
import { asyncPopulatedHome } from '../store/populatedHomepage/action'

export default function HomePages() {
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
}, [])

  useEffect(() => {
    dispatch(asyncPopulatedHome())
  }, [dispatch])

  const {populatedHomeReducer} = useSelector(state => state)

  if (populatedHomeReducer.isLoading === true) {
    return (
      <Stack top="0" left="0" right="0" bottom="0" zIndex="99999" bg="white" w="full" position="fixed" justifyContent="center" alignItems="center">
        <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
        />
      </Stack>
    )
  }

  return (
    <>
      <CarrouselHero />
      <ProdukKami />
      <BisnisKami />
      <TentangKami />
      {/* <AnakPerusahaan /> */}
      <GaleriVideo />
      <BeritaTerkini />
      {/* <MediaSosial /> */}
      <ButtonWhatsapp />
    </>
  )
}
