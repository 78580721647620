import { Box, Flex, GridItem, Image, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FadeInSection from '../atoms/MyFade'

export default function BisnisKami() {
  const {bisnisKamiReducer} = useSelector(state => state)

  const navigate = useNavigate()
  const color = ['rgba(80, 181, 9, 0.8)', 'rgba(45, 108, 92, 0.9)', 'rgba(164, 102, 90, 0.9)']

  return (
    <Box w="full" p={{ base: "4", md: "16" }} bg="gray.100">
      <FadeInSection>
        <Text fontSize="3xl" fontWeight="bold" textAlign="center">Bisnis Kami</Text>
      </FadeInSection>
      <Flex justifyContent="center" alignItems="center" gap="8" flexWrap="wrap" mt="16">
        {
          bisnisKamiReducer.data.map((bisnisKami, id) => (
            <GridItem
              w={{ base: "100%", md: "40%", xl: "28%" }}
              transform="translateY(0)"
              transitionTimingFunction="ease-in"
              transitionDuration="0.3s"
              _hover={{
                transform: "translateY(-15px)"
              }}
              rounded="lg"
              overflow="hidden"
              shadow="md"
              key={id}>
              <Image sx={{ height: '230px' }} objectFit="cover" src={bisnisKami.lokasi_gambar} alt={bisnisKami.judul} title={bisnisKami.judul} width="100%" height="100%" />
              <Box bg="white">
                <Stack p="4">
                  <Text fontWeight="bold" fontSize="xl">{bisnisKami.judul}</Text>
                  <Text>{bisnisKami.deskripsi_singkat}</Text>
                  <Text cursor="pointer" onClick={() => navigate(`/bisnis-kami/${bisnisKami.id}`, {state: {data: bisnisKami, c: color[id]}})} fontWeight="bold" color="#0A3C7E" fontSize="md">Selengkapnya</Text>
                </Stack>
              </Box>
            </GridItem>
          ))
        }
      </Flex>
    </Box>
  )
}
