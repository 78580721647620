import { Box, Container, Grid, GridItem, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { FaMedal } from 'react-icons/fa'
import { INITIAL_DATA } from '../../../utils/INITIAL_DATA'

export default function NilaiPerusahaan({refNilaiPerusahaan}) {
  return (
    <Box bg="gray.50" py="30" ref={refNilaiPerusahaan}>
      <Container maxW="6xl">
        <Text fontSize="30" fontWeight="bold" color="#0A3C7E">Nilai Perusahaan</Text>

        <Stack mt="5">
          <Grid templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr", xl: "1fr 1fr 1fr 1fr" }} gap="5">
            {
              INITIAL_DATA.profilPerusahaan.nilaiPerusahaan.data.map((nilaiPerusahaan, id) => (
                <GridItem p="5" shadow="md" borderRadius="10" key={id}>
                  <Stack spacing="5">
                    <FaMedal size="50" color="#0A3C7E" />
                    <Text fontWeight="bold" fontSize="xl" color="#0A3C7E">{nilaiPerusahaan.name}</Text>
                    <Text fontSize="sm">{nilaiPerusahaan.content}</Text>
                  </Stack>
                </GridItem>
              ))
            }
          </Grid>
        </Stack>
      </Container>
    </Box>
  )
}
