import React, { useEffect } from "react";
import { Box, Container, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { CardDewanKomisaris } from "../components/CardDewanKomisaris";
import { useDispatch, useSelector } from "react-redux";
import { asyncReceiveDewanKomisaris } from "../store/dewanKomisaris/action";
import { RotatingLines } from "react-loader-spinner";

export default function DewanKomisaris() {
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(asyncReceiveDewanKomisaris())
  }, [dispatch])

  const {dewanKomisarisReducer} = useSelector(state => state)

  if (dewanKomisarisReducer.isLoading === true) {
    return (
      <Stack top="0" left="0" right="0" bottom="0" zIndex="99999" bg="white" w="full" position="fixed" justifyContent="center" alignItems="center">
        <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
        />
      </Stack>
    )
  }

  return (
    <Box mt="65px">
      <Box w="full" h="80" position="relative">
        <Image src="https://saranapatra.com/images/bgBanner.jpg" w="full" h="full" />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(10, 60, 126, 0.9)"
        >
          <Stack w="full" h="full" justifyContent="center" alignItems="center">
            <Stack justifyContent="center" alignItems="center" gap="4">
              <Text fontSize="4xl" fontWeight="bold" color="white" textAlign="center">
                Dewan Komisaris & Direksi
              </Text>
              {/* <Stack direction={{ base: "column", md: "row" }} gap={{ base: 0, md: "4" }}>
              </Stack> */}
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box bg="gray.50" py="30">
        <Container maxW="6xl">
          <Stack mt="5">
            <Flex justifyContent="center" alignItems="center" gap="8" flexWrap="wrap">
              {dewanKomisarisReducer.data.map(
                (dewanKomisaris, id) => (
                  <CardDewanKomisaris
                    key={id}
                    content={dewanKomisaris.biografi_singkat}
                    gambar={dewanKomisaris.lokasi_gambar}
                    jabatan={dewanKomisaris.jabatan}
                    name={dewanKomisaris.nama}
                  />
                )
              )}
            </Flex>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
