import {
  Box,
  Button,
  Grid,
  GridItem,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { FaInfoCircle } from "react-icons/fa";

export const DaftarRekananMampu = () => {
  return (
    <Box mt="65" minH="100vh">
      <Box mx="auto" marginTop="24" maxW="6xl" p="5" shadow="md" rounded="md">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack spacing="0.5">
            <Text fontWeight="bold" fontSize="3xl">
              DAFTAR REKANAN
            </Text>
            <Text fontWeight="bold" fontSize="3xl">
              PT Sarana Patra Jateng
            </Text>
          </Stack>
          <Box width="52" height="24">
            <Image src="https://saranapatra.com/images/logo_spj_new.png" w="full" h="full" />
          </Box>
        </Stack>
        <Stack mt="10" spacing="5">
          <Stack>
            <Stack marginBottom="5" spacing="0.5">
              <Text fontWeight="bold" fontSize="2xl">
                Informasi Perusahaan/Perorangan
              </Text>
              <Box sx={{ height: "2px" }} bg="gray" />
            </Stack>
            <Stack>
              <Stack direction="row" spacing="2">
                <Text fontWeight="bold">Email</Text>
                <Text color="red">*</Text>
              </Stack>
              <Input variant="outline" />
            </Stack>
            <Stack>
              <Stack direction="row" spacing="2">
                <Text fontWeight="bold">Nama Perusahaan/Perorangan</Text>
                <Text color="red">*</Text>
              </Stack>
              <Input variant="outline" />
            </Stack>
            <Stack>
              <Grid gridTemplateColumns="1fr 1fr" gap="5">
                <GridItem>
                  <Stack direction="row" spacing="2">
                    <Text fontWeight="bold">Bidang Usaha</Text>
                    <Text color="red">*</Text>
                  </Stack>
                  <Input variant="outline" />
                </GridItem>
                <GridItem>
                  <Stack direction="row" spacing="2">
                    <Text fontWeight="bold">NPWP</Text>
                    <Text color="red">*</Text>
                  </Stack>
                  <Input variant="outline" />
                </GridItem>
              </Grid>
            </Stack>
            <Stack>
              <Stack direction="row" spacing="2">
                <Text fontWeight="bold">Alamat Perusahaan/Perorangan</Text>
                <Text color="red">*</Text>
              </Stack>
              <Textarea variant="outline"></Textarea>
            </Stack>
            <Stack>
              <Grid gridTemplateColumns="1fr 1fr" gap="5">
                <GridItem>
                  <Stack direction="row" spacing="2">
                    <Text fontWeight="bold">No. Telp Kantor/Fax</Text>
                    <Text color="red">*</Text>
                  </Stack>
                  <Input variant="outline" />
                </GridItem>
                <GridItem>
                  <Stack direction="row" spacing="2">
                    <Text fontWeight="bold">Alamat Website</Text>
                    <Text color="red">*</Text>
                  </Stack>
                  <Input variant="outline" />
                </GridItem>
              </Grid>
            </Stack>
          </Stack>

          <Stack>
            <Stack marginBottom="5" spacing="0.5">
              <Text fontWeight="bold" fontSize="2xl">
                Contact Person
              </Text>
              <Box sx={{ height: "2px" }} bg="gray" />
            </Stack>
            <Stack>
              <Grid gridTemplateColumns="1fr 1fr" gap="5">
                <GridItem>
                  <Stack direction="row" spacing="2">
                    <Text fontWeight="bold">Nama CP</Text>
                    <Text color="red">*</Text>
                  </Stack>
                  <Input variant="outline" />
                </GridItem>
                <GridItem>
                  <Stack direction="row" spacing="2">
                    <Text fontWeight="bold">Telp/HP CP</Text>
                    <Text color="red">*</Text>
                  </Stack>
                  <Input variant="outline" />
                </GridItem>
              </Grid>
            </Stack>
            <Stack>
              <Grid gridTemplateColumns="1fr 1fr" gap="5">
                <GridItem>
                  <Stack direction="row" spacing="2">
                    <Text fontWeight="bold">Alamat Workshop</Text>
                    <Text color="red">*</Text>
                  </Stack>
                  <Input variant="outline" />
                </GridItem>
                <GridItem>
                  <Stack direction="row" spacing="2">
                    <Text fontWeight="bold">Telp/HP Workshop</Text>
                    <Text color="red">*</Text>
                  </Stack>
                  <Input variant="outline" />
                </GridItem>
              </Grid>
            </Stack>
          </Stack>

          <Stack>
            <Stack marginBottom="5" spacing="0.5">
              <Text fontWeight="bold" fontSize="2xl">
                Akta & Notaris
              </Text>
              <Box sx={{ height: "2px" }} bg="gray" />
            </Stack>
            <Stack>
              <Grid gridTemplateColumns="1fr 1fr" gap="5">
                <GridItem>
                  <Stack direction="row" spacing="2">
                    <Text fontWeight="bold">Nomor Akta Pendirian</Text>
                    <Text color="red">*</Text>
                  </Stack>
                  <Input variant="outline" />
                </GridItem>
                <GridItem>
                  <Stack direction="row" spacing="2">
                    <Text fontWeight="bold">Tanggal Akta</Text>
                    <Text color="red">*</Text>
                  </Stack>
                  <Input variant="outline" />
                </GridItem>
              </Grid>
            </Stack>
            <Stack>
              <Stack direction="row" spacing="2">
                <Text fontWeight="bold">Nama Notaris</Text>
                <Text color="red">*</Text>
              </Stack>
              <Input variant="outline" />
            </Stack>
            <Stack>
              <Stack direction="row" spacing="2">
                <Text fontWeight="bold">
                  Akta Perubahan Terakhir (Diskripsi Perubahan Pada Akta
                  Terakhir)
                </Text>
                <Text color="red">*</Text>
              </Stack>
              <Input variant="outline" />
            </Stack>
            <Stack>
              <Grid gridTemplateColumns="1fr 1fr" gap="5">
                <GridItem>
                  <Stack direction="row" spacing="2">
                    <Text fontWeight="bold">Nomor Akta Perubahan</Text>
                    <Text color="red">*</Text>
                  </Stack>
                  <Input variant="outline" />
                </GridItem>
                <GridItem>
                  <Stack direction="row" spacing="2">
                    <Text fontWeight="bold">Tanggal Akta Perubahan</Text>
                    <Text color="red">*</Text>
                  </Stack>
                  <Input variant="outline" />
                </GridItem>
              </Grid>
            </Stack>
            <Stack>
              <Stack direction="row" spacing="2">
                <Text fontWeight="bold">Nama Notaris Perubahan</Text>
                <Text color="red">*</Text>
              </Stack>
              <Input variant="outline" />
            </Stack>
          </Stack>

          <Stack>
            <Stack marginBottom="5" spacing="0.5">
              <Text fontWeight="bold" fontSize="2xl">
                Perijinan
              </Text>
              <Box sx={{ height: "2px" }} bg="gray" />
            </Stack>
            <Stack>
              <Grid gridTemplateColumns="380px 1fr" gap="5">
                <GridItem>
                  <Stack>
                    <Text fontWeight="bold" fontSize="3xl">
                      TDP
                    </Text>
                    <Text>Tanda Daftar Perusahaan</Text>
                    <Box bg="gray" sx={{ height: "2px" }} />
                  </Stack>
                </GridItem>
                <GridItem>
                  <Stack>
                    <Grid templateColumns="1fr 1fr" gap="5">
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Nomor TDP</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Masa Berlaku TDP</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                    </Grid>
                    <Grid templateColumns="1fr 1fr" gap="5">
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Kualifikasi TDP</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Klasifikasi TDP</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                    </Grid>
                  </Stack>
                </GridItem>
              </Grid>

              <Grid gridTemplateColumns="380px 1fr" gap="5">
                <GridItem>
                  <Stack>
                    <Text fontWeight="bold" fontSize="3xl">
                      NIB
                    </Text>
                    <Text>Nomor Induk Berusaha</Text>
                    <Box bg="gray" sx={{ height: "2px" }} />
                  </Stack>
                </GridItem>
                <GridItem>
                  <Stack>
                    <Grid templateColumns="1fr 1fr" gap="5">
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Nomor NIB</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Masa Berlaku NIB</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                    </Grid>
                    <Grid templateColumns="1fr 1fr" gap="5">
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Kualifikasi NIB</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Klasifikasi NIB</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                    </Grid>
                  </Stack>
                </GridItem>
              </Grid>

              <Grid gridTemplateColumns="380px 1fr" gap="5">
                <GridItem>
                  <Stack>
                    <Text fontWeight="bold" fontSize="3xl">
                      SIUP
                    </Text>
                    <Text>Surat Izin Usaha Perdagangan</Text>
                    <Box bg="gray" sx={{ height: "2px" }} />
                  </Stack>
                </GridItem>
                <GridItem>
                  <Stack>
                    <Grid templateColumns="1fr 1fr" gap="5">
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Nomor SIUP</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Masa Berlaku SIUP</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                    </Grid>
                    <Grid templateColumns="1fr 1fr" gap="5">
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Kualifikasi SIUP</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Klasifikasi SIUP</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                    </Grid>
                  </Stack>
                </GridItem>
              </Grid>

              <Grid gridTemplateColumns="380px 1fr" gap="5">
                <GridItem>
                  <Stack>
                    <Text fontWeight="bold" fontSize="3xl">
                      SIUJK
                    </Text>
                    <Text>Surat Izin Usaha Jasa Konstruksi</Text>
                    <Box bg="gray" sx={{ height: "2px" }} />
                  </Stack>
                </GridItem>
                <GridItem>
                  <Stack>
                    <Grid templateColumns="1fr 1fr" gap="5">
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Nomor SIUJK</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Masa Berlaku SIUJK</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                    </Grid>
                    <Grid templateColumns="1fr 1fr" gap="5">
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Kualifikasi SIUJK</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Klasifikasi SIUJK</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                    </Grid>
                  </Stack>
                </GridItem>
              </Grid>

              <Grid gridTemplateColumns="380px 1fr" gap="5">
                <GridItem>
                  <Stack>
                    <Text fontWeight="bold" fontSize="3xl">
                      SBU
                    </Text>
                    <Text>Sertifikat Badan Usaha</Text>
                    <Box bg="gray" sx={{ height: "2px" }} />
                  </Stack>
                </GridItem>
                <GridItem>
                  <Stack>
                    <Grid templateColumns="1fr 1fr" gap="5">
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Nomor SBU</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Masa Berlaku SBU</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                    </Grid>
                    <Grid templateColumns="1fr 1fr" gap="5">
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Kualifikasi SBU</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                      <GridItem>
                        <Stack>
                          <Box>
                            <Stack direction="row" spacing="2">
                              <Text fontWeight="bold">Klasifikasi SBU</Text>
                              <Text color="red">*</Text>
                            </Stack>
                            <Input variant="outline" />
                          </Box>
                        </Stack>
                      </GridItem>
                    </Grid>
                  </Stack>
                </GridItem>
              </Grid>
            </Stack>
            <Stack>
              <Box>
                <Stack direction="row" spacing="2">
                  <Text fontWeight="bold">Ijin Domisili</Text>
                  <Text color="red">*</Text>
                </Stack>
                <Input variant="outline" />
              </Box>
            </Stack>
          </Stack>

          <Stack>
            <Stack marginBottom="5" spacing="0.5">
              <Text fontWeight="bold" fontSize="2xl">
                Kepemilikan Saham & Pengurus Perusahaan
              </Text>
              <Box sx={{ height: "2px" }} bg="gray" />
            </Stack>
            <Stack>
              <Stack direction="row" spacing="2">
                <Text fontWeight="bold">
                  Kepemilikan Saham (Nama/PT dan Porsi Saham)
                </Text>
                <Text color="red">*</Text>
              </Stack>
              <Textarea variant="outline"></Textarea>
            </Stack>
            <Stack>
              <Grid gridTemplateColumns="1fr 1fr" gap="5">
                <GridItem>
                  <Stack direction="row" spacing="2">
                    <Text fontWeight="bold">
                      Direksi (Daftar Pengurus Perusahaan/Direksi)
                    </Text>
                    <Text color="red">*</Text>
                  </Stack>
                  <Textarea variant="outline" />
                </GridItem>
                <GridItem>
                  <Stack direction="row" spacing="2">
                    <Text fontWeight="bold">
                      Dewan Komisaris (Daftar Pengurus Perusahaan/Dewan
                      Komisaris)
                    </Text>
                    <Text color="red">*</Text>
                  </Stack>
                  <Textarea variant="outline" />
                </GridItem>
              </Grid>
            </Stack>
          </Stack>

          <Stack>
            <Stack marginBottom="5" spacing="0.5">
              <Text fontWeight="bold" fontSize="2xl">
                Link File Pendukung
              </Text>
              <Box sx={{ height: "2px" }} bg="gray" />
            </Stack>
            <Stack>
              <Stack direction="row" spacing="2">
                <Text fontWeight="bold">
                  Link Google Drive File-File Pendukung
                </Text>
                <Text color="gray">
                  <FaInfoCircle size="13px" />
                </Text>
              </Stack>
              <Input variant="outline" />
            </Stack>
          </Stack>

          <Stack>
            <Button bg="blue.400" color="white" _hover={{ bg: "#82c0ff" }}>
              Submit Form
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
