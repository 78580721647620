import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import HomePages from "./pages/HomePages";
import Navbar from "./components/Navbar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProfilPerusahaan from "./pages/ProfilPerusahaan";
import Footer from "./components/Footer";
import Penghargaan from "./pages/Penghargaan";
import DewanKomisaris from "./pages/DewanKomisaris";
import StrukturOragnisasi from "./pages/StrukturOragnisasi";
import StrukturTataKelola from "./pages/StrukturTataKelola";
import ManajemenResiko from "./pages/ManajemenResiko";
import WhistleBlowingSystem from "./pages/WhistleBlowingSystem";
import InformasiPemegangSaham from "./pages/InformasiPemegangSaham";
import KeterbukaanInformasi from "./pages/KeterbukaanInformasi";
import AlurPermohonan from "./pages/AlurPermohonan";
import BeritaDanKegiatan from "./pages/BeritaDanKegiatan";
import PengadaanBarang from "./pages/PengadaanBarang";
import Karir from "./pages/Karir";
import Kontak from "./pages/Kontak";
import NotFound from "./pages/NotFound";
import { DetailBisnisKami } from "./components/DetailBisnisKami";
import { DaftarRekananMampu } from "./pages/DaftarRekananMampu";

// basename="/testing-landingpage-new"

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePages />} />

        <Route path="/bisnis-kami/:id" element={<DetailBisnisKami />} />

        <Route path="/profil-perusahaan" element={<ProfilPerusahaan />} />
        <Route path="/sertifikat" element={<Penghargaan />} />
        <Route path="/dewan-komisaris-dan-direksi" element={<DewanKomisaris />} />
        <Route path="/struktur-organisasi" element={<StrukturOragnisasi />} />

        <Route path="/struktur-tata-kelola" element={<StrukturTataKelola />} />
        <Route path="/manajemen-resiko" element={<ManajemenResiko />} />
        <Route path="/whistle-blowing-system" element={<WhistleBlowingSystem />} />

        <Route path="/informasi-pemegang-saham" element={<InformasiPemegangSaham />} />
        {/* <Route path="/laporan-keuangan" element={<LaporanKeuangan />} />
        <Route path="/laporan-tahunan" element={<LaporanTahunan />} />
        <Route path="/rups" element={<RUPS />} /> */}
        <Route path="/keterbukaan-informasi-lainnya" element={<KeterbukaanInformasi />} />

        <Route path="/alur-permohonan" element={<AlurPermohonan />} />
        {/* <Route path="/informasi-berkala" element={<InformasiBerkala />} />
        <Route path="/informasi-setiap-saat" element={<InformasiSetiapSaat />} />
        <Route path="/informasi-serta-merta" element={<InformasiSertaMerta />} />
        <Route path="/informasi-dikecualikan" element={<InformasiDikecualikan />} /> */}

        <Route path="/berita-dan-kegiatan" element={<BeritaDanKegiatan />} />
        <Route path="/pengadaan-barang" element={<PengadaanBarang />} />

        {/* <Route path="/tjslp" element={<TJSLP />} /> */}

        <Route path="/karir" element={<Karir />} />

        <Route path="/daftar-rekanan-mampu" element={<DaftarRekananMampu />} />
        <Route path="/kontak" element={<Kontak />} />

        <Route path="/*" element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
