import { Box, Button, Grid, GridItem, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, useDisclosure } from '@chakra-ui/react'

export const CardDewanKomisaris = ({ name, jabatan, gambar, content }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <GridItem height="2xl" borderRadius="10" overflow="hidden" shadow="md">
        <Stack sx={{ width: '100%', height: '70%' }}>
          <Image src={gambar} width="100%" height="100%" />
        </Stack>
        <Stack bg="gray.50" p="5">
          <Stack>
            <Text fontWeight="bold" textAlign="center">{name}</Text>
            <Text textAlign="center">{jabatan}</Text>
          </Stack>
          <Button onClick={onOpen}>Detail</Button>
        </Stack>
      </GridItem>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent mt="90">
          <ModalHeader>Dewan Komisaris & Direksi</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns={{ base: "1fr", md: "300px 1fr" }} gap="5">
              <GridItem>
                <Box>
                  <Image src={gambar} width="100%" height="100%" />
                </Box>
              </GridItem>
              <GridItem>
                <Stack>
                  <Stack>
                    <Text fontWeight="bold">{name}</Text>
                    <Text>{jabatan}</Text>
                  </Stack>
                  <Stack>
                    <Text>{content}</Text>
                  </Stack>
                </Stack>
              </GridItem>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Tutup</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}