import {
  Box,
  Container,
  Flex,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import {
  FaChevronRight,
  FaCopyright,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getYear } from "../utils/helper";

export default function Footer() {
  const navigate = useNavigate()
  return (
    <>
      <Container maxW="6xl">
        <Flex
          direction={{ base: "column", lg: "row" }}
          w="full"
          py="16"
          justifyContent="space-between"
          flexWrap="wrap"
          gap="10"
        >
          <Box w="52" height="24" p="3">
            <Image src="https://saranapatra.com/images/logo_spj_new.png" />
          </Box>
          <Stack spacing="2">
            <Text fontWeight="bold" fontSize="20">
              Tentang Kami
            </Text>
            <Stack spacing="2">
              <Stack direction="row" align="center">
                <FaChevronRight color="gray" />
                  <Text
                    onClick={() => navigate('/profil-perusahaan')}
                    color="gray.700"
                    cursor="pointer"
                    _hover={{ color: "#0A3C7E" }}
                  >
                    Profil Perusahaan
                  </Text>
              </Stack>
              <Stack direction="row" align="center">
                <FaChevronRight color="gray" />
                  <Text
                    onClick={() => navigate('/sertifikat')}
                    color="gray.700"
                    cursor="pointer"
                    _hover={{ color: "#0A3C7E" }}
                  >
                    Sertifikat
                  </Text>
              </Stack>
              <Stack direction="row" align="center">
                <FaChevronRight color="gray" />
                  <Text
                    onClick={() => navigate('/dewan-komisaris-dan-direksi')}
                    color="gray.700"
                    cursor="pointer"
                    _hover={{ color: "#0A3C7E" }}
                  >
                    Dewan Komisaris & Direksi
                  </Text>
              </Stack>
              <Stack direction="row" align="center">
                <FaChevronRight color="gray" />
                  <Text
                    onClick={() => navigate('/struktur-organisasi')}
                    color="gray.700"
                    cursor="pointer"
                    _hover={{ color: "#0A3C7E" }}
                  >
                    Struktur Organisasi
                  </Text>
              </Stack>
            </Stack>
          </Stack>

          <Stack spacing="2">
            <Text fontWeight="bold" fontSize="20">
              Tautan Luar
            </Text>
            <Stack spacing="2">
              <Stack direction="row" align="center">
                <FaChevronRight color="gray" />
                  <Text
                    onClick={() => window.open('https://jatengprov.go.id/', '_blank')}
                    color="gray.700"
                    cursor="pointer"
                    _hover={{ color: "#0A3C7E" }}
                  >
                    Jateng Prov
                  </Text>
              </Stack>
              <Stack direction="row" align="center">
                <FaChevronRight color="gray" />
                  <Text
                    onClick={() => window.open('https://laporgub.jatengprov.go.id/', '_blank')}
                    color="gray.700"
                    cursor="pointer"
                    _hover={{ color: "#0A3C7E" }}
                  >
                    Lapor Gub
                  </Text>
              </Stack>
              <Stack direction="row" align="center">
                <FaChevronRight color="gray" />
                  <Text
                    onClick={() => window.open('https://ppid.jatengprov.go.id/', '_blank')}
                    color="gray.700"
                    cursor="pointer"
                    _hover={{ color: "#0A3C7E" }}
                  >
                    PPID Jateng
                  </Text>
              </Stack>
              <Stack direction="row" align="center">
                <FaChevronRight color="gray" />
                  <Text
                    onClick={() => window.open('https://spjt.co.id/', '_blank')}
                    color="gray.700"
                    cursor="pointer"
                    _hover={{ color: "#0A3C7E" }}
                  >
                    PT SPJT
                  </Text>
              </Stack>
            </Stack>
          </Stack>

          <Stack spacing="2">
            <Text fontWeight="bold" fontSize="20">
              Hubungi Kami
            </Text>
            <Stack spacing="2">
              <Stack direction="row" align="center">
                <FaChevronRight color="gray" />
                <Text
                  color="gray.700"
                  cursor="pointer"
                  _hover={{ color: "#0A3C7E" }}
                >
                  024 - 76630507
                </Text>
              </Stack>
              <Stack direction="row" align="center">
                <FaChevronRight color="gray" />
                <Text
                  color="gray.700"
                  cursor="pointer"
                  _hover={{ color: "#0A3C7E" }}
                >
                  spj@saranapatra.com
                </Text>
              </Stack>
              <Stack direction="row" align="center">
                <FaChevronRight color="gray" />
                <Text
                  color="gray.700"
                  cursor="pointer"
                  _hover={{ color: "#0A3C7E" }}
                >
                  JL. Pamularsih No.58 Semarang
                </Text>
              </Stack>
              <Stack direction="row" align="center">
                <FaChevronRight color="gray" />
                <Text
                  color="gray.700"
                  cursor="pointer"
                  _hover={{ color: "#0A3C7E" }}
                >
                  Sen-Jum : 08:00 - 17:00
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Flex>
      </Container>

      <Box bg="#0A3C7E" py="5">
        <Container maxW="6xl">
          <Flex
            h="100"
            direction={{ base: "column", md: "row" }}
            alignItems="center"
            justify="space-between"
          >
            <Stack direction="row" alignItems="center" justify="center">
              <FaCopyright color="white" />
              <Text color="white">{getYear()} PT. Sarana Patra Jateng</Text>
            </Stack>
            <Stack direction="row" alignItems="center" justify="center">
              <Text color="white" textAlign="center">
                Kebijakan Privasi | Syarat & Ketentuan
              </Text>
            </Stack>
            <Stack direction="row" alignItems="center" justify="center">
              <FaYoutube
                onClick={() =>
                  window.open("https://www.youtube.com/@spjtofficial", "_blank")
                }
                color="white"
                size={25}
                cursor="pointer"
              />
              <FaInstagram
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/saranapatrajateng",
                    "_blank"
                  )
                }
                color="white"
                size={25}
                cursor="pointer"
              />
            </Stack>
          </Flex>
        </Container>
      </Box>
    </>
  );
}
