import {
  Box,
  Container,
  Grid,
  GridItem,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { asyncReceiveSertifikat } from "../store/sertifikasi/action";
import { formatDate } from "../utils/helper";

export default function Penghargaan() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(asyncReceiveSertifikat());
  }, [dispatch]);

  const { sertifikasiReducer } = useSelector((state) => state);

  if (sertifikasiReducer.isLoading) {
    return (
      <Stack
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bg="white"
        zIndex="9999"
        justifyContent="center"
        alignItems="center"
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </Stack>
    );
  }

  return (
    <Box mt="65px">
      <Box w="full" h="80" position="relative">
        <Image src="https://saranapatra.com/images/bgBanner.jpg" w="full" h="full" />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(10, 60, 126, 0.9)"
        >
          <Stack w="full" h="full" justifyContent="center" alignItems="center">
            <Stack justifyContent="center" alignItems="center" gap="4">
              <Text fontSize="4xl" fontWeight="bold" color="white" textAlign="center">
                Sertifikat
              </Text>
              {/* <Stack direction={{ base: "column", md: "row" }} gap={{ base: 0, md: "4" }}>
              </Stack> */}
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box bg="gray.50" py="30">
        <Container maxW="6xl">
          <Text fontSize="30" fontWeight="bold" color="#0A3C7E">
            Daftar Sertifikat Yang dimiliki PT Sarana Patra Jateng
          </Text>
          <Stack mt="5">
            <Grid templateColumns="1fr" gap="5">
              {sertifikasiReducer.data.map((daftarPenghargaan, key) => (
                <GridItem p="5" shadow="md" borderRadius="10" key={key}>
                  <Grid
                    templateColumns={{ base: "1fr", md: "1fr 1fr" }}
                    gap="5"
                  >
                    <GridItem>
                      <Box overflow="hidden">
                        <Box w="100%" h="100%">
                          <Box
                            w="100%"
                            h="100%"
                            borderRadius="10"
                            transition="0.3s ease-in-out"
                            _hover={{
                              transform: "scale(1.2)",
                            }}
                          >
                            <Image
                              src={daftarPenghargaan.lokasi_gambar}
                              w="100%"
                              h="100%"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Stack gap="5">
                        <Text color="gray">
                          {formatDate(daftarPenghargaan.created_at)}
                        </Text>
                        <Text fontWeight="bold" fontSize="4xl" lineHeight="1.2">
                          {daftarPenghargaan.judul}
                        </Text>
                        <Text>{daftarPenghargaan.content}</Text>
                      </Stack>
                    </GridItem>
                  </Grid>
                </GridItem>
              ))}
            </Grid>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
