import React, { useEffect, useRef } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  GridItem,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

export default function Karir() {
  const refTenagaBerpengalaman = useRef(null);
  const refLulusanBaru = useRef(null);
  const refMagang = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollSection = (ref) => {
    // console.log(ref.current.offsetTop)
    // ref.current.scrollIntoView({ behavior: 'smooth' })
    window.scrollTo({ top: ref.current.offsetTop + 10, behavior: "smooth" });
  };

  return (
    <Box bg="gray.50" mt="65" minH="100vh">
      <Box w="full" h="80" position="relative">
        <Image src="https://saranapatra.com/images/bgBanner.jpg" w="full" h="full" />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(10, 60, 126, 0.9)"
        >
          <Stack w="full" h="full" justifyContent="center" alignItems="center">
            <Stack justifyContent="center" alignItems="center" gap="4">
              <Text fontSize="4xl" fontWeight="bold" color="white">
                Karir
              </Text>
              <Stack
                direction={{ base: "column", md: "row" }}
                gap={{ base: 0, md: "4" }}
              >
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  color="white"
                  cursor="pointer"
                  _hover={{ color: "yellow" }}
                  onClick={() => scrollSection(refTenagaBerpengalaman)}
                >
                  Tenaga Berpengalaman
                </Text>
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  color="white"
                  cursor="pointer"
                  _hover={{ color: "yellow" }}
                  onClick={() => scrollSection(refLulusanBaru)}
                >
                  Lulusan Baru
                </Text>
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  color="white"
                  cursor="pointer"
                  _hover={{ color: "yellow" }}
                  onClick={() => scrollSection(refMagang)}
                >
                  Magang
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Container maxW="6xl" py="30">
        <Stack spacing="28">
          <Stack ref={refTenagaBerpengalaman}>
            <Text fontSize="3xl" fontWeight="bold" color="#0A3C7E">
              Tenaga Berpengalaman
            </Text>
            <Grid
              templateColumns={{
                base: "1fr",
                md: "1fr 1fr",
                lg: "1fr 1fr 1fr",
                xl: "1fr 1fr 1fr",
              }}
              gap={{ base: "5" }}
              mt="5"
            >
              <GridItem p="3" bg="#123075" borderRadius="10">
                <Stack spacing="5">
                  <Box overflow="hidden">
                    <Image
                      w="full"
                      transition="0.3s ease-in-out"
                      src="https://saranapatra.com/images/rupslb_rkap2023-300x200.png"
                      borderRadius="10"
                      _hover={{ transform: "scale(1.2)" }}
                    />
                  </Box>
                  <Stack>
                    <Text color="white" fontWeight="bold">
                      RUPSLB RJPP & RKAP 2023 SPJT Optimis Realisasikan Rencana…
                    </Text>
                    <Button>Selengkapnya</Button>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem p="3" bg="#123075" borderRadius="10">
                <Stack spacing="5">
                  <Box overflow="hidden">
                    <Image
                      w="full"
                      transition="0.3s ease-in-out"
                      src="https://saranapatra.com/images/rupslb_rkap2023-300x200.png"
                      borderRadius="10"
                      _hover={{ transform: "scale(1.2)" }}
                    />
                  </Box>
                  <Stack>
                    <Text color="white" fontWeight="bold">
                      RUPSLB RJPP & RKAP 2023 SPJT Optimis Realisasikan Rencana…
                    </Text>
                    <Button>Selengkapnya</Button>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem p="3" bg="#123075" borderRadius="10">
                <Stack spacing="5">
                  <Box overflow="hidden">
                    <Image
                      w="full"
                      transition="0.3s ease-in-out"
                      src="https://saranapatra.com/images/rupslb_rkap2023-300x200.png"
                      borderRadius="10"
                      _hover={{ transform: "scale(1.2)" }}
                    />
                  </Box>
                  <Stack>
                    <Text color="white" fontWeight="bold">
                      RUPSLB RJPP & RKAP 2023 SPJT Optimis Realisasikan Rencana…
                    </Text>
                    <Button>Selengkapnya</Button>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem p="3" bg="#123075" borderRadius="10">
                <Stack spacing="5">
                  <Box overflow="hidden">
                    <Image
                      w="full"
                      transition="0.3s ease-in-out"
                      src="https://saranapatra.com/images/rupslb_rkap2023-300x200.png"
                      borderRadius="10"
                      _hover={{ transform: "scale(1.2)" }}
                    />
                  </Box>
                  <Stack>
                    <Text color="white" fontWeight="bold">
                      RUPSLB RJPP & RKAP 2023 SPJT Optimis Realisasikan Rencana…
                    </Text>
                    <Button>Selengkapnya</Button>
                  </Stack>
                </Stack>
              </GridItem>
            </Grid>
          </Stack>

          <Stack ref={refLulusanBaru}>
            <Text fontSize="3xl" fontWeight="bold" color="#0A3C7E">
              Lulusan Baru
            </Text>
            <Grid
              templateColumns={{
                base: "1fr",
                md: "1fr 1fr",
                lg: "1fr 1fr 1fr",
                xl: "1fr 1fr 1fr",
              }}
              gap={{ base: "5" }}
              mt="5"
            >
              <GridItem p="3" bg="#123075" borderRadius="10">
                <Stack spacing="5">
                  <Box overflow="hidden">
                    <Image
                      w="full"
                      transition="0.3s ease-in-out"
                      src="https://saranapatra.com/images/rupslb_rkap2023-300x200.png"
                      borderRadius="10"
                      _hover={{ transform: "scale(1.2)" }}
                    />
                  </Box>
                  <Stack>
                    <Text color="white" fontWeight="bold">
                      RUPSLB RJPP & RKAP 2023 SPJT Optimis Realisasikan Rencana…
                    </Text>
                    <Button>Selengkapnya</Button>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem p="3" bg="#123075" borderRadius="10">
                <Stack spacing="5">
                  <Box overflow="hidden">
                    <Image
                      w="full"
                      transition="0.3s ease-in-out"
                      src="https://saranapatra.com/images/rupslb_rkap2023-300x200.png"
                      borderRadius="10"
                      _hover={{ transform: "scale(1.2)" }}
                    />
                  </Box>
                  <Stack>
                    <Text color="white" fontWeight="bold">
                      RUPSLB RJPP & RKAP 2023 SPJT Optimis Realisasikan Rencana…
                    </Text>
                    <Button>Selengkapnya</Button>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem p="3" bg="#123075" borderRadius="10">
                <Stack spacing="5">
                  <Box overflow="hidden">
                    <Image
                      w="full"
                      transition="0.3s ease-in-out"
                      src="https://saranapatra.com/images/rupslb_rkap2023-300x200.png"
                      borderRadius="10"
                      _hover={{ transform: "scale(1.2)" }}
                    />
                  </Box>
                  <Stack>
                    <Text color="white" fontWeight="bold">
                      RUPSLB RJPP & RKAP 2023 SPJT Optimis Realisasikan Rencana…
                    </Text>
                    <Button>Selengkapnya</Button>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem p="3" bg="#123075" borderRadius="10">
                <Stack spacing="5">
                  <Box overflow="hidden">
                    <Image
                      w="full"
                      transition="0.3s ease-in-out"
                      src="https://saranapatra.com/images/rupslb_rkap2023-300x200.png"
                      borderRadius="10"
                      _hover={{ transform: "scale(1.2)" }}
                    />
                  </Box>
                  <Stack>
                    <Text color="white" fontWeight="bold">
                      RUPSLB RJPP & RKAP 2023 SPJT Optimis Realisasikan Rencana…
                    </Text>
                    <Button>Selengkapnya</Button>
                  </Stack>
                </Stack>
              </GridItem>
            </Grid>
          </Stack>

          <Stack ref={refMagang}>
            <Text fontSize="3xl" fontWeight="bold" color="#0A3C7E">
              Magang
            </Text>
            <Grid
              templateColumns={{
                base: "1fr",
                md: "1fr 1fr",
                lg: "1fr 1fr 1fr",
                xl: "1fr 1fr 1fr",
              }}
              gap={{ base: "5" }}
              mt="5"
            >
              <GridItem p="3" bg="#123075" borderRadius="10">
                <Stack spacing="5">
                  <Box overflow="hidden">
                    <Image
                      w="full"
                      transition="0.3s ease-in-out"
                      src="https://saranapatra.com/images/rupslb_rkap2023-300x200.png"
                      borderRadius="10"
                      _hover={{ transform: "scale(1.2)" }}
                    />
                  </Box>
                  <Stack>
                    <Text color="white" fontWeight="bold">
                      RUPSLB RJPP & RKAP 2023 SPJT Optimis Realisasikan Rencana…
                    </Text>
                    <Button>Selengkapnya</Button>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem p="3" bg="#123075" borderRadius="10">
                <Stack spacing="5">
                  <Box overflow="hidden">
                    <Image
                      w="full"
                      transition="0.3s ease-in-out"
                      src="https://saranapatra.com/images/rupslb_rkap2023-300x200.png"
                      borderRadius="10"
                      _hover={{ transform: "scale(1.2)" }}
                    />
                  </Box>
                  <Stack>
                    <Text color="white" fontWeight="bold">
                      RUPSLB RJPP & RKAP 2023 SPJT Optimis Realisasikan Rencana…
                    </Text>
                    <Button>Selengkapnya</Button>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem p="3" bg="#123075" borderRadius="10">
                <Stack spacing="5">
                  <Box overflow="hidden">
                    <Image
                      w="full"
                      transition="0.3s ease-in-out"
                      src="https://saranapatra.com/images/rupslb_rkap2023-300x200.png"
                      borderRadius="10"
                      _hover={{ transform: "scale(1.2)" }}
                    />
                  </Box>
                  <Stack>
                    <Text color="white" fontWeight="bold">
                      RUPSLB RJPP & RKAP 2023 SPJT Optimis Realisasikan Rencana…
                    </Text>
                    <Button>Selengkapnya</Button>
                  </Stack>
                </Stack>
              </GridItem>
              <GridItem p="3" bg="#123075" borderRadius="10">
                <Stack spacing="5">
                  <Box overflow="hidden">
                    <Image
                      w="full"
                      transition="0.3s ease-in-out"
                      src="https://saranapatra.com/images/rupslb_rkap2023-300x200.png"
                      borderRadius="10"
                      _hover={{ transform: "scale(1.2)" }}
                    />
                  </Box>
                  <Stack>
                    <Text color="white" fontWeight="bold">
                      RUPSLB RJPP & RKAP 2023 SPJT Optimis Realisasikan Rencana…
                    </Text>
                    <Button>Selengkapnya</Button>
                  </Stack>
                </Stack>
              </GridItem>
            </Grid>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
