import { Box, Image } from "@chakra-ui/react";
import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import { useSelector } from "react-redux";

export default function CarrouselHero() {
  const {sliderReducer} = useSelector(state => state)

  return (
    <Box mt="67px">
      <Swiper
        speed="1000"
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        autoplay={{
          pauseOnMouseEnter: true,
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        {
          sliderReducer.data.map((slider, id) => (
            <SwiperSlide key={id}>
              <Box w="full" h="90vh">
                <Image
                  w="full"
                  h="full"
                  src={slider.lokasi_gambar}
                  title={slider.title}
                  onClick={() => slider?.link.includes('http' || 'https') ? window.open(slider?.link, '_blank') : {}}
                />
              </Box>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </Box>
  );
}
