import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  FaRegEnvelope,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaGlobeAsia,
  FaClock,
} from "react-icons/fa";
import emailJs from "@emailjs/browser";
import { useInput } from "../hooks/useInput";
import Swal from "sweetalert2";

export default function Kontak() {
  const namaLengkapAnda = useInput('')
  const emailAnda = useInput('')
  const noHandphoneAnda = useInput('')
  const pesananAnda = useInput('')

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault()

    const templateParams = {
      nama_lengkap: namaLengkapAnda.value,
      email_anda: emailAnda.value,
      no_telpon_anda: noHandphoneAnda.value,
      pesanan_anda: pesananAnda.value,
    }

    emailJs
      .send(
        'service_6gfjbe6',
        'template_0i6kwl6',
        templateParams,
        'ZWdNwjQ_jlSTtUsyg'
      ).then((result) => {
        Swal.fire({
          icon: 'success',
          title: 'Pesan Berhasil Terkirim',
          showConfirmButton: false,
          timer: 1500
        })
    
        namaLengkapAnda.onResetInput()
        emailAnda.onResetInput()
        noHandphoneAnda.onResetInput()
        pesananAnda.onResetInput()
      }, (error) => {
      })
  }

  return (
    <Box mt="65" minH="100vh" paddingY="50px" position="relative">
      <Box
        height="115vh"
        position="absolute"
        top="0"
        left="0"
        right="0"
        zIndex="-999"
      >
        <Image src="https://saranapatra.com/images/cust-service.jpg" w="full" h="full" />
      </Box>
      <Container maxW="6xl" py="30" shadow="md" bg="white">
        <Grid gap="5" templateColumns={{ base: "1fr", md: "1fr 1fr" }}>
          <GridItem p="5">
            <Stack spacing="5">
              <Stack direction="row">
                <Text fontWeight="bold" fontSize="3xl" color="gray.800">
                  Hubungi
                </Text>
                <Text fontWeight="bold" fontSize="3xl" color="#0A3C7E">
                  Kami
                </Text>
              </Stack>
              <Text>
                Halo sahabat SPJ. Terima kasih telah menghubungi SPJ. Kami
                sangat senang dan siap membantu Anda.
              </Text>
              <Stack spacing="5">
                <Stack direction="row" align="center">
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    w="10"
                    h="10"
                    bg="#0A3C7E"
                    rounded="full"
                  >
                    <FaRegEnvelope size="25px" color="white" />
                  </Stack>
                  <Text fontWeight="bold">spj@saranapatra.com</Text>
                </Stack>
                <Stack direction="row" align="center">
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    w="10"
                    h="10"
                    bg="#0A3C7E"
                    rounded="full"
                  >
                    <FaPhoneAlt size="25px" color="white" />
                  </Stack>
                  <Text fontWeight="bold">024 - 76630507</Text>
                </Stack>
                <Stack direction="row" align="center">
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    w="10"
                    h="10"
                    bg="#0A3C7E"
                    rounded="full"
                  >
                    <FaGlobeAsia size="25px" color="white" />
                  </Stack>
                  <Text fontWeight="bold">www.saranapatra.com</Text>
                </Stack>
                <Stack direction="row" align="center">
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    w="10"
                    h="10"
                    bg="#0A3C7E"
                    rounded="full"
                  >
                    <FaMapMarkerAlt size="25px" color="white" />
                  </Stack>
                  <Stack spacing="0">
                    <Text fontWeight="bold">PT. Sarana Patra Jateng</Text>
                    <Text>JL. Pamularsih Raya No.58 Semarang</Text>
                  </Stack>
                </Stack>
                <Stack direction="row" align="center">
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    w="10"
                    h="10"
                    bg="#0A3C7E"
                    rounded="full"
                  >
                    <FaClock size="25px" color="white" />
                  </Stack>
                  <Stack spacing="0">
                    <Text fontWeight="bold">Jam Kerja</Text>
                    <Text>Senin - Jumat : 08:00 - 17:00</Text>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </GridItem>
          <GridItem height="fit-content" alignSelf="end">
            <form onSubmit={sendEmail}>
              <Stack spacing="5">
                <FormControl>
                  <FormLabel fontWeight="bold">Nama Lengkap</FormLabel>
                  <Input placeholder="First Name" name="nama_lengkap" value={namaLengkapAnda.value} onChange={namaLengkapAnda.onChange} />
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="bold">Email</FormLabel>
                  <Input placeholder="First Name" name="email_anda" value={emailAnda.value} onChange={emailAnda.onChange} />
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="bold">
                    No. Handphone / Whatsapp
                  </FormLabel>
                  <Input placeholder="First Name" name="no_telpon_anda" value={noHandphoneAnda.value} onChange={noHandphoneAnda.onChange} />
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="bold">Pesan Anda</FormLabel>
                  <Textarea placeholder="First Name" name="pesanan_anda" value={pesananAnda.value} onChange={pesananAnda.onChange} />
                </FormControl>
                <Button type="submit" bg="blue.300" color="white" _hover={{ bg: "blue.200" }}>
                  Submit Form
                </Button>
              </Stack>
            </form>
          </GridItem>
        </Grid>
      </Container>
      <Box minH="100vh" marginTop="16">
        <iframe
          title="Location My Office"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.174726766988!2d110.39053997303115!3d-6.988689668437806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e708b3a5439c2ef%3A0x1ecaca2dcecc0c3a!2sPT%20Sarana%20Patra%20Jateng!5e0!3m2!1sid!2sid!4v1683271410390!5m2!1sid!2sid"
          width="100%"
          height="550px"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box>
    </Box>
  );
}
