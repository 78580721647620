import React from 'react'
import { Box, Container, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react'

export default function VisiMisi({refVisiMisi}) {
  return (
    <>
      <Box bg="gray.50" py="30" ref={refVisiMisi}>
        <Container maxW="6xl">
          <Text fontSize="30" fontWeight="bold" color="#0A3C7E">Visi Perusahaan</Text>
          <Stack>
            <Text mt="3">
              Mempertegas eksistensi dengan meningkatkan usaha yang berdampak positif pada sosial dan perekonomian Jawa Tengah.
            </Text>
            <Text>
              Visi perusahaan menggambarkan semangat yang tinggi dalam menjalankan tugas yang diamanatkan oleh induk Perusahaan PT Sarana Pembangunan Jawa Tengah dan Pemerintah Provinsi Jawa Tengah dalam pengelolaan perusahaan disekitar Infrastruktur, Jasa dan Perdagangan.
            </Text>
          </Stack>
        </Container>
        <Container maxW="6xl" mt="5">
          <Text fontSize="30" fontWeight="bold" color="#0A3C7E">Misi Perusahaan</Text>
          <Stack>
            <Box>
              <OrderedList>
                <ListItem>Peningkatan managemen dengan sumber daya manusia profesional dan pemanfaatan tekologi dan sistem yang terintegrasi</ListItem>
                <ListItem>Memanfaatkan aset yang terintegrasi dalam rangka optimalisasi sumber daya yang dimiliki</ListItem>
                <ListItem>Menyokong kebijakan Induk Perusahaan dan Pemetintah dalam rangka meningkatkan perekonomian dan kesejahteraan masyarakat Jawa Tengah</ListItem>
                <ListItem>Menerapkan prinsip tata kelola perusahaan yang baik dan menerapkan managemen perbaikan berkelanjutan</ListItem>
              </OrderedList>
            </Box>
            <Text>
              PT. Sarana Patra Jateng mengukuhkan posisi menjadi perusahaan yang dapat diandalkan baik sebagai fungsi enabler aktifitas usaha maupun stabilizer komoditas, dengan menjunjung tinggi profesionalisme dan kompetitif serta memiliki nilai kepekaan dan rasa tanggung jawab bagi kepentingan masyarakat dan menjunjung tinggi etika dalam menjalankan usaha.
            </Text>
          </Stack>
        </Container>
      </Box>
    </>
  )
}
