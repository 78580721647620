import { Box, Image, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import LatarBelakang from "../components/tentang_spjt/profil_perusahaan/LatarBelakang";
import MaksudTujuan from "../components/tentang_spjt/profil_perusahaan/MaksudTujuan";
import VisiMisi from "../components/tentang_spjt/profil_perusahaan/VisiMisi";
import NilaiPerusahaan from "../components/tentang_spjt/profil_perusahaan/NilaiPerusahaan";

export default function ProfilPerusahaan() {
  const refLatarBelakang = useRef(null);
  const refMaksudTujuan = useRef(null);
  const refVisiMisi = useRef(null);
  const refNilaiPerusahaan = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollSection = (ref) => {
    // console.log(ref.current.offsetTop)
    // ref.current.scrollIntoView({ behavior: 'smooth' })
    window.scrollTo({ top: ref.current.offsetTop + 30, behavior: "smooth" });
  };

  return (
    <Box mt="65px">
      <Box w="full" h="80" position="relative">
        <Image src="https://saranapatra.com/images/bgBanner.jpg" w="full" h="full" />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(10, 60, 126, 0.9)"
        >
          <Stack w="full" h="full" justifyContent="center" alignItems="center">
            <Stack justifyContent="center" alignItems="center" gap="4">
              <Text fontSize="4xl" fontWeight="bold" color="white" textAlign="center">
                Profil Perusahaan
              </Text>
              <Stack
                direction={{ base: "column", md: "row" }}
                gap={{ base: 0, md: "4" }}
              >
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  color="white"
                  cursor="pointer"
                  _hover={{ color: "yellow" }}
                  onClick={() => scrollSection(refLatarBelakang)}
                >
                  Latar Belakang
                </Text>
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  color="white"
                  cursor="pointer"
                  _hover={{ color: "yellow" }}
                  onClick={() => scrollSection(refMaksudTujuan)}
                >
                  Maksud & Tujuan
                </Text>
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  color="white"
                  cursor="pointer"
                  _hover={{ color: "yellow" }}
                  onClick={() => scrollSection(refVisiMisi)}
                >
                  Visi & Misi
                </Text>
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  color="white"
                  cursor="pointer"
                  _hover={{ color: "yellow" }}
                  onClick={() => scrollSection(refNilaiPerusahaan)}
                >
                  Nilai Perusahaan
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <LatarBelakang refLatarBelakang={refLatarBelakang} />
      <MaksudTujuan refMaksudTujuan={refMaksudTujuan} />
      <VisiMisi refVisiMisi={refVisiMisi} />
      <NilaiPerusahaan refNilaiPerusahaan={refNilaiPerusahaan} />
    </Box>
  );
}
