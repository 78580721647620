import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const DetailBisnisKami = (idx) => {
  const { state: detailBisnisKami } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (detailBisnisKami === null) {
    return null;
  }

  return (
    <Box mt="65px">
      <Box w="full" h="80" position="relative">
        <Image src="https://saranapatra.com/images/bgBanner.jpg" w="full" h="full" objectFit="cover" />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg={detailBisnisKami.c}
        >
          <Stack w="full" h="full" justifyContent="center" alignItems="center">
            <Stack justifyContent="center" alignItems="center" gap="4">
              <Text
                fontSize="4xl"
                fontWeight="bold"
                color="white"
                textAlign="center"
              >
                {detailBisnisKami.data.judul}
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box maxW="6xl" bg="" p="5" mx="auto" mt="10">
        <Flex direction={{ base: "column", sm: "row" }}>
          <Box flex="1" p="5">
            <Image src={detailBisnisKami.data.lokasi_gambar} />
          </Box>
          <Box flex="3" p="5">
            <Stack>
              <Text fontWeight="bold" fontSize="3xl">
                {detailBisnisKami.data.judul}
              </Text>
              <Text>{detailBisnisKami.data.deskripsi_detail}</Text>
            </Stack>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
