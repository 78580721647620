import { getBerita, getBisnisKami, getGaleri, getProdukKami, getSliders } from "../../utils/INITIAL_DATA"
import { beritaAction } from "../berita/slice"
import { bisnisKamiAction } from "../bisnisKami/slice"
import { galeriAction } from "../galeri/slice"
import { produkKamiAction } from "../produkKami/slice"
import { sliderAction } from "../slider/slice"
import { populatedHomeAction } from "./slice"

export const asyncPopulatedHome = () => async (dispatch) => {
    dispatch(populatedHomeAction.setLoadedData())
    try {
        const slider = await getSliders()
        const produkKami = await getProdukKami()
        const bisnisKami = await getBisnisKami()
        const galery = await getGaleri()
        const berita = await getBerita()
        
        dispatch(sliderAction.setSuccessData(slider))
        dispatch(produkKamiAction.setSuccessData(produkKami))
        dispatch(bisnisKamiAction.setSuccessData(bisnisKami))
        dispatch(galeriAction.setSuccessData(galery))
        dispatch(beritaAction.setSuccessData(berita))

        dispatch(populatedHomeAction.setSuccessLoadedData())
    }catch(err) {

    }
}