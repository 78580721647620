import React, { useEffect } from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";

export default function AlurPermohonan() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box mt="65px">
      <Box w="full" h="80" position="relative">
        <Image src="https://saranapatra.com/images/bgBanner.jpg" w="full" h="full" />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(10, 60, 126, 0.9)"
        >
          <Stack w="full" h="full" justifyContent="center" alignItems="center">
            <Stack justifyContent="center" alignItems="center" gap="4">
              <Text fontSize="4xl" fontWeight="bold" color="white" textAlign="center">
                Alur Permohonan
              </Text>
              {/* <Stack direction={{ base: "column", md: "row" }} gap={{ base: 0, md: "4" }}>
              </Stack> */}
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box bg="gray.50" py="30">
        <Container maxW="6xl">
          <Box w="full" h="500" mt="10">
            <Image
              objectFit={{ base: "contain", md: "fill" }}
              width="100%"
              height="100%"
              src="https://saranapatra.com/images/alur-permohonan-informasi-1024x724.jpg"
            />
          </Box>
          <Flex gap="5" direction="column">
            <Center>
              <Stack direction={{ base: "column", md: "row" }}>
                <Text textAlign="center">Klik link</Text>
                <Link
                  href="https://forms.gle/Q9WkMa28iGkMDSM37"
                  color="red"
                  _hover={{ textDecoration: "none" }}
                  target="_blank"
                >
                  Form Permohonan Informasi Publik
                </Link>
              </Stack>
            </Center>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
}
