import { createSlice } from "@reduxjs/toolkit";

const sertifikasiSlice = createSlice({
    name: "sertifikasi",
    initialState: {
        data: [],
        isLoading: true
    },
    reducers: {
        setInitialData(state, action) {
            state.data = []
            state.isLoading = true
        },
        setSuccessData(state, action) {
            state.data = action.payload
            state.isLoading = false
        }
    }
})

export const sertifikasiReducer = sertifikasiSlice.reducer
export const sertifikasiAction = sertifikasiSlice.actions