import { Box, Container, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";

export default function NotFound() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box mt="16" py="16" minH="100vh" px={{ base: "8" }}>
      <Container maxW="3xl" p="10" rounded="10" shadow="md">
        <Flex justify="center" alignItems="center" gap="10" flexWrap="wrap">
          <Box w="40">
            <Image src="https://saranapatra.com/images/alien.png" w="100%" h="100%" />
          </Box>
          <Stack spacing="0">
            <Text
              fontWeight="bold"
              fontSize="6xl"
              color="red.500"
              textAlign={{ base: "center" }}
            >
              404
            </Text>
            <Text
              fontWeight="bold"
              fontSize="4xl"
              color="red.500"
              textAlign={{ base: "center" }}
            >
              Not Found
            </Text>
          </Stack>
        </Flex>
      </Container>
    </Box>
  );
}
