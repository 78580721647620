import { Box, Container, Text } from '@chakra-ui/react'
import React from 'react'

export default function MaksudTujuan({refMaksudTujuan}) {
  return (
    <>
      <Box bg="gray.50" py="30" ref={refMaksudTujuan}>
        <Container maxW="6xl">
          <Text fontSize="30" fontWeight="bold" color="#0A3C7E">Maksud dan Tujuan</Text>

          <Text mt="5">
          Menjalankan usaha yang bergerak di bidang industri, perbengkelan, perdagangan, pengangkutan dan pergudangan yang mampu memberikan pelayanan sebaik-baiknya kepada masyarakat, mendukung pengembangan wilayah, meningkatkan perekonomian daerah dan memberikan kontribusi terhadap pendapatan daerah, untuk meningkatkan pertumbuhan dan perkembangan perekonomian daerah dalam rangka meningkatkan kesejahteraan masyarakat daerah.
          </Text>
        </Container>
      </Box>
    </>
  )
}
